export class RecoveryAccountError {
  static errorCode = '001'

  constructor(
    public message: string,
    public mobilePhone: string,
    public email: string,
    public recoveryHash: string
  ) {}
}
