import React from 'react'

import { TypeDepartmentsMenuState, TypeKernel } from '@k_frontend/core'
import { StoreApi, UseBoundStore } from 'zustand'

export type FooterProps = {
  clean?: boolean
  isPrime: boolean
  kernel: TypeKernel
  useDepartmentsMenu: UseBoundStore<StoreApi<TypeDepartmentsMenuState>>
  hasDrawer?: boolean
  cookieIsMobile?: string
  KabumLogo: React.ReactNode
}

export type TypeKabumInfoProps = {
  isPrime?: boolean
}
