import styled from 'styled-components'

import { TypeFooterLinksPropsStyled } from '../FooterLinks.types'

export const LinkItem = styled.a<TypeFooterLinksPropsStyled>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm} 0;
  ${({ theme }) => theme.font(12, 18, 600)};
  text-decoration: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.white(500)};
  border-bottom: 1px solid
    ${({ theme, isPrime }) =>
      isPrime ? theme.colors.black(700) : theme.colors.primary(500)};
`
