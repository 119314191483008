import { walletCardsModel } from 'models/wallet/v1/cards'
import { WalletCardsModel } from 'models/wallet/v1/cards.types'
import { axios } from 'services/axios'
import { queryString } from 'utils/queryString'

import {
  PostWallerCardResponse,
  PostWalletCardParams,
  WalletCardsRequest,
  WalletCardsResponse
} from './cards.types'

const BACKEND_WALLET = 'wallet/v1'
export async function getWalletCards({
  clientId,
  clientSession
}: WalletCardsRequest): Promise<WalletCardsModel> {
  const toQueryString = queryString({
    customer_id: clientId,
    customer_session: clientSession
  })

  try {
    const { data } = await axios.get<WalletCardsResponse>(
      `${BACKEND_WALLET}/cards${toQueryString}`
    )

    return walletCardsModel(data)
  } catch (error) {
    throw new Error(error)
  }
}

export async function PostWalletCard({
  params,
  onFail,
  onSuccess,
  hasCard
}: PostWalletCardParams) {
  try {
    const payload = {
      card_token: params.card_token,
      card_nick_name: params.cardAlias,
      customer_id: params.clientId,
      main_card: hasCard ? Boolean(params.mainCard) : true,
      card_exp_date: params.card_exp_date,
      card_number: params.cardNumber,
      customer: {
        customer_document: params.customerAccount.customerDocument,
        customer_name: params.customerAccount.customerName
      },
      card_security_code: params.cardCVV,
      holder_document_number: params.holder_document_number,
      card_brand: params.cardBrand,
      card_number_encripty: params.card_number,
      customer_session: params.session,
      card_holder_birth_date: params.cardBirth,
      holder_name: params.holder_name
    }

    await axios.post<PostWallerCardResponse>(`${BACKEND_WALLET}/cards`, payload)

    onSuccess()
  } catch (error) {
    onFail(
      error?.response?.data?.message ||
        'Houve um erro ao cadastrar seu cartão, tente novamente mais tarde.'
    )
  }
}

export async function getWalletHealthCheck() {
  try {
    const { data } = await axios.get<{ enable_card_register: boolean }>(
      `wallet/healthcheck`
    )

    return data
  } catch (error) {
    throw new Error(error)
  }
}
