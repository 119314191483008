import { useCallback } from 'react'

import { HandtalkState, useHandtalk } from '@k_frontend/core'
import {
  IconA11Y,
  IconCloseModal,
  IconLibras,
  IconQuestionAlternate
} from '@k_frontend/icons'
import { Separator } from '@k_frontend/ui'
import * as Popover from '@radix-ui/react-popover'
import theme from 'theme'

import * as S from './AccessibilityMenu.styles'
import {
  AccessibilityMenuProps,
  OnInteractOutsideEvent
} from './AcessibilityMenu.types'

const useHandtalkSelector = (state: HandtalkState) => ({
  isHandtalkActive: state.isHandtalkActive,
  handleHandtalkToggle: state.handleHandtalkToggle,
  getLabel: state.getLabel
})

export function AccessibilityMenu({
  defaultOpen = false
}: AccessibilityMenuProps) {
  const { getLabel, handleHandtalkToggle, isHandtalkActive } =
    useHandtalk(useHandtalkSelector)

  const IconA11yColor = isHandtalkActive
    ? theme.colors.secondary(400)
    : '#ffffffcc'

  const onInteractOutside = useCallback((event: OnInteractOutsideEvent) => {
    event.preventDefault()
  }, [])

  return (
    <Popover.Root defaultOpen={defaultOpen}>
      <Popover.Trigger asChild>
        <S.ButtonTrigger aria-label='Abrir opções de acessibilidade'>
          <IconA11Y color={IconA11yColor} aria-hidden />
        </S.ButtonTrigger>
      </Popover.Trigger>

      <Popover.Portal>
        <S.Content align='end' onInteractOutside={onInteractOutside}>
          <S.Title>Acessibilidade</S.Title>

          <Separator background='#EFEFEF' decorative />

          <S.Wrapper>
            <S.Item
              as='button'
              onClick={handleHandtalkToggle}
              aria-pressed={isHandtalkActive}
              aria-label={getLabel('full')}
            >
              <IconLibras aria-hidden />
              <S.ItemTitle>{getLabel('clean')}</S.ItemTitle>
            </S.Item>

            <S.Item href='/acessibilidade'>
              <IconQuestionAlternate aria-hidden />
              <S.ItemTitle>Acessibilidade</S.ItemTitle>
            </S.Item>
          </S.Wrapper>

          <S.Close aria-label='Fechar'>
            <IconCloseModal color={theme.colors.black(800)} aria-hidden />
          </S.Close>
        </S.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
