import { ToastsContainer } from 'components/ToastCard/ToastContainer'
import { createContext, useReducer } from 'react'
import { toastReducer } from 'reducers/Toast'

const initialState = {
  toasts: []
}

export const ToastContext = createContext(null)

export const ToastContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(toastReducer, initialState)

  function addToast(type, message) {
    const id = Math.floor(Math.random() * 10000000)
    dispatch({ type: 'ADD_TOAST', payload: { id, message, type } })
  }

  function removeToast(id) {
    dispatch({ type: 'DELETE_TOAST', payload: id })
  }

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastsContainer toasts={state.toasts} />
      {children}
    </ToastContext.Provider>
  )
}
