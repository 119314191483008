import { getFlag } from 'services/blackfriday'
import Cookies from 'universal-cookie'

export async function handleFlagBlackFriday(
  setFlagBlackFriday: (arg: boolean) => void
) {
  const cookieInstance = new Cookies()
  let flag = false
  const cookieFlag = cookieInstance.get('kbm_blackfriday')
  if (cookieFlag) {
    flag = cookieFlag === 'true'
  } else {
    flag = await getFlag()
    cookieInstance.set('kbm_blackfriday', flag, { maxAge: 300, path: '/' })
  }
  setFlagBlackFriday(flag)
}
