/* eslint-disable react-hooks/rules-of-hooks */
import {
  NewtailBannerData,
  getNewTailBannerAds,
  useBannerSize,
  useUser
} from '@k_frontend/core'
import { useQuery } from '@tanstack/react-query'
import kernel from 'settings/kernel'

import { MonetizaDepartmentsProps } from './useBannerMonetiza.types'

export function useBannerMonetizaDepartments({
  departmentName,
  sectionName,
  categoryName,
  subCategoryName,
  menuId
}: MonetizaDepartmentsProps) {
  const { session } = useUser()

  const bannerSize = useBannerSize({
    mobile: 'department_mobile',
    tablet: 'department_tablet',
    desktop: 'department_default',
    desktopLarge: 'department_default'
  })

  function formatCategoryName(
    departmentName: string,
    sectionName?: string,
    categoryName?: string,
    subCategoryName?: string
  ) {
    if (sectionName && categoryName && subCategoryName) {
      return `${departmentName} > ${sectionName} > ${categoryName}  > ${subCategoryName}`
    }

    if (sectionName && categoryName) {
      return `${departmentName} > ${sectionName} > ${categoryName}`
    }

    if (sectionName) {
      return `${departmentName} > ${sectionName}`
    }

    return departmentName
  }

  return useQuery<NewtailBannerData>({
    queryKey: ['getDeparmentsBanner', bannerSize],
    queryFn: () =>
      getNewTailBannerAds(kernel, {
        session_id: session,
        menu_id: menuId,
        origin: bannerSize === 'department_mobile' ? 'mobile' : 'desktop',
        context: 'category',
        category_name: formatCategoryName(
          departmentName,
          sectionName,
          categoryName,
          subCategoryName
        ),
        placements: {
          departamentos: {
            quantity: 1,
            size: bannerSize,
            types: ['banner']
          }
        }
      }),
    staleTime: 1000 * 60 * 10
  })
}

export function useBannerMonetizaLogin(session: string) {
  const bannerSize = useBannerSize({
    mobile: 'login_mobile',
    tablet: 'login_default',
    desktop: 'login_default',
    desktopLarge: 'login_default'
  })

  return useQuery<NewtailBannerData>({
    queryKey: ['getLoginBanner', bannerSize],
    queryFn: () =>
      getNewTailBannerAds(kernel, {
        session_id: session,
        origin: bannerSize === 'login_mobile' ? 'mobile' : 'desktop',
        context: 'category',
        category_name: 'LOGIN',
        placements: {
          login: {
            quantity: 1,
            size: bannerSize,
            types: ['banner']
          }
        }
      }),
    staleTime: 1000 * 60 * 10
  })
}
