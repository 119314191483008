import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  #__next {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }

  body, html{
    padding: 0px !important;
    scroll-behavior: smooth;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  div.genesys-app {
    iframe#genesys-mxg-frame {
      bottom: 0px !important;
      right: 0px !important;
    }
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    }
  }

  :root {
    --background-principal: #F2F3F4;
    --text-grey: #42464D;
    --text-grey-mid: #565C69;
    --text-grey-weak: #7F858D;
    --filter-text: #3F3B3B;
    --orange-strong: #E35214;
    --orange-text: #FC6B0F;
    --orange-weak: #FF6500;
    --white: #FFFFFF;
    --white-weak: #F5F5F5;
    --blue: #0060b1;
    --green: #2DC26E;
    --green-text: #1F9050;
    --red: #E72626;
    --grey-trash-icon: #B6BBC2;
    --grey-line: #DEE0E4;
  }

  button, a {
    cursor: pointer;
  }

  * {
    margin: 0;
    padding: 0;
    border: none;
    list-style: none;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif!important;
    font-size: 16px;
  }

  /* Sobrescrevendo CSS do script do selo do compre e confie */
  #seloEconfy img.compreConfieImg {
    height: 60px !important;
    width: 57px !important;
    position: static  !important;
  }

  /**Sobrescrevendo CSS go script goAdpt */
  .svelte-1tbswcw {
    font-size: 12px;
  }

  .ReactModal__Overlay--after-open{
    z-index: 9999 !important;
  }

  /* Loading botão smartHint */
  .btn-loading {
    position: relative;

    &:before {
      content: "";
      width: 22px;
      height: 22px;

      background: transparent;
      border: 2px solid #ffffff55;
      border-top-color: #fff;
      border-radius: 50%;
      margin-right: 0.5rem;

      animation: spin 0.6s linear infinite;
    }


    &.btn-sh:disabled {
      cursor: not-allowed;
    }

    svg {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    input[type="text"]:focus,
    input[type="password"]:focus,
    textarea:focus, select:focus {
      font-size: 16px;
    }
  }

  footer {
    @media (max-width: 600px) {
      div#selosFooter {
        div {
          gap: 0.5rem;
        }
        img {
          width: 3.5rem;
        }
      }
    }
  }

  div.grecaptcha-badge {
    @media (max-width: 600px) {
      visibility: hidden;
    }
  }
`
