import Router from 'next/router'

import axios from 'axios'
import CookiesInterface from 'interfaces/cookies'

type LogoutResponse = {
  data: {
    sucesso: true
  }
}

export async function Logout() {
  const ClassCookiesInterface = new CookiesInterface()
  const signOutData = {
    id_cliente: ClassCookiesInterface.getClientId(),
    session: ClassCookiesInterface.getSession()
  }

  const { data }: LogoutResponse = await axios.post(
    '/api/core/logout',
    signOutData
  )
  if (data?.sucesso) {
    Router.push('/')
  }
}
