import { useEffect, useId, useState } from 'react'

import dynamic from 'next/dynamic'
import Link from 'next/link'

import { TypeDepartmentsMenuState } from '@k_frontend/core'
import { SrOnly } from '@k_frontend/ui'
import axios from 'axios'

import { IconMagaluLogo } from '@k_frontend/icons'
import * as S from './Footer.styles'
import { FooterProps } from './Footer.types'
import FooterLinks, { FooterJsonProps } from './FooterLinks'

const Stamps = dynamic(
  () => import('./Stamps').then((module) => module.Stamps),
  { ssr: false }
)

const KabumInfo = dynamic(
  () => import('./KabumInfo').then((module) => module.KabumInfo),
  { ssr: false }
)

export function Footer({
  clean = false,
  isPrime,
  kernel,
  useDepartmentsMenu,
  hasDrawer = false,
  KabumLogo,
  cookieIsMobile
}: FooterProps) {
  const titleId = useId()
  const [footerJson, setFooterJson] = useState<FooterJsonProps>()
  const openDepartments = useDepartmentsMenu(
    (state: TypeDepartmentsMenuState) => state.openDepartments
  )
  let fullFooterContent = null
  let cleanedFooterContent = null

  if (!clean) {
    fullFooterContent = (
      <>
        <FooterLinks
          isPrime={isPrime}
          kernel={kernel}
          openDepartments={openDepartments}
          footerJson={footerJson}
        />
      </>
    )
  } else {
    cleanedFooterContent = (
      <S.LinksFooterCleaned isPrime={isPrime}>
        <div>
          <Link href='/politicas'>Políticas do Site e Marketplace</Link>
          <Link href='/privacidade'>Políticas de Privacidade</Link>
          <Link href='/portaldeprivacidade'>
            Exerça seus Direitos de Privacidade
          </Link>
        </div>
      </S.LinksFooterCleaned>
    )
  }

  async function getFooterJson() {
    const cachedFooter = sessionStorage.getItem('footerJsonStatic')

    if (cachedFooter) {
      setFooterJson(JSON.parse(cachedFooter))
    } else {
      const { data } = await axios.get(kernel.STATIC_FOOTER_JSON)
      setFooterJson(data)
      sessionStorage.setItem('footerJsonStatic', JSON.stringify(data))
    }
  }

  useEffect(() => {
    getFooterJson()
  }, [])

  return (
    <S.Container data-testid='footer' id='footer' aria-labelledby={titleId}>
      <SrOnly as='h2' id={titleId}>
        KaBuM! Rodapé
      </SrOnly>
      {fullFooterContent}
      <Stamps kernel={kernel} />
      {cleanedFooterContent}
      <KabumInfo
        isPrime={isPrime}
        hasDrawer={hasDrawer}
        kernel={kernel}
        footerJson={footerJson}
        KabumLogo={KabumLogo}
        cookieIsMobile={cookieIsMobile || 'true'}
      />
    </S.Container>
  )
}
