import styled from 'styled-components'

import * as S from '../Header.styles'
import { HeaderStyleProps } from '../Header.types'

export const Container = styled(S.Container)`
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    overflow: visible;
  }
`

export const UserInfoContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-grow: 1;
  max-width: max-content;
`

export const ContainerWrapper = styled(S.ContainerWrapper)<HeaderStyleProps>`
  padding: ${({ theme }) => theme.spacing.xxs} 0;
`

export const SearchbarSection = styled(S.SearchbarSection)`
  flex: 1;
  width: 100%;
`

export const ContainerLogo = styled(S.ContainerLogo)`
  margin: 0 ${({ theme }) => theme.spacing.lg};
`

export const HandtalkWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.md};
  margin-right: auto;
`
