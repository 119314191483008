import { useState } from 'react'

import { IconArrowOrangeRight } from '@k_frontend/icons'
import { SocialMedia } from '@k_frontend/ui'

import { MenuLinksModal } from '../../MenuLinksModal'
import * as S from './FooterLinksMobile.styles'
import { TypeFooterLinksMobileProps } from './FooterLinksMobile.types'

export function FooterLinksMobile({
  isPrime,
  openDepartments,
  footerJson
}: TypeFooterLinksMobileProps) {
  function handleDepartments() {
    openDepartments()
  }
  const [open, setOpen] = useState(false)
  const [dataModal, setDataModal] = useState<any>([])

  function handleDataModal(data, title) {
    setOpen(true)
    setDataModal({ data, title })
  }

  return (
    <>
      <S.LinkItem
        as='button'
        isPrime={isPrime}
        onClick={handleDepartments}
        data-testid='buttonDepartments'
      >
        Departamentos
        <IconArrowOrangeRight color='#ffffff' />
      </S.LinkItem>
      {footerJson?.linksMobile.map((item, index) => (
        <div key={index}>
          {item.hasChildLinks ? (
            <S.LinkItem
              as='button'
              isPrime={isPrime}
              onClick={() =>
                handleDataModal(footerJson?.[item.childLinks], item?.title)
              }
            >
              {item.title}
              <IconArrowOrangeRight color='#ffffff' />
            </S.LinkItem>
          ) : (
            <S.LinkItem isPrime={isPrime} href={item.link}>
              {item.title}
              <IconArrowOrangeRight color='#ffffff' />
            </S.LinkItem>
          )}
        </div>
      ))}
      {footerJson?.socialMedia && (
        <SocialMedia socialMediaLinks={footerJson.socialMedia} />
      )}
      {open && (
        <MenuLinksModal
          title={dataModal.title}
          linksList={dataModal.data}
          isOpenModal={open}
          setIsOpenModal={setOpen}
        />
      )}
    </>
  )
}
