import axios, { AxiosResponse } from 'axios'
import kernel from 'settings/kernel'

import { openCSATWidgetResponseProps } from './openCSATWidget.types'

export async function openCSATWidget(conversationId: string): Promise<void> {
  try {
    const { data } = await axios.get<
      string,
      AxiosResponse<openCSATWidgetResponseProps>
    >(`/api/account/csat/${conversationId}`)

    data.customerEmail === null
      ? localStorage.removeItem('solucxWidgetLog-')
      : localStorage.removeItem('solucxWidgetLog-' + data.customerEmail)

    window.openCSAT(
      kernel.API_KEY_SOLUCX_CSAT,
      data.customerName,
      data.customerEmail === null ? '' : data.customerEmail,
      data.agentName,
      data.conversationId,
      data.employerId
    )
    window.toggleMessenger()
  } catch (error) {
    throw error.response
  }
}
