import { Col } from '@k_frontend/ui'
import styled from 'styled-components'

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0 ${({ theme }) => theme.spacing.xxxs};
  width: 100%;
  max-height: ${({ theme }) => theme.rem(360)};

  span {
    ${({ theme }) => theme.font(12, 18)};
    color: ${({ theme }) => theme.colors.white(500)};
  }

  a:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    max-height: ${({ theme }) => theme.rem(480)};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktopLarge}) {
    max-height: ${({ theme }) => theme.rem(420)};
  }
`

export const ColumnTitle = styled.h3`
  ${({ theme }) => theme.font(14, 18, 700)};
  color: ${({ theme }) => theme.colors.black(400)};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`

export const ColCustom = styled(Col)`
  flex-direction: column;

  &:last-child {
    padding: 0;
    margin-right: 0;
  }

  &.departmentsColumn {
    a {
      max-width: 50%;
    }
  }

  #socialMediaTitle {
    margin-top: ${({ theme }) => theme.rem(37)};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }

  #downloadAppsColumn {
    margin-top: ${({ theme }) => theme.spacing.lg};
  }

  #centralSacTitle {
    margin-top: ${({ theme }) => theme.spacing.xxs};
    margin-bottom: 0px;
  }

  #emailTitle {
    margin-top: ${({ theme }) => theme.spacing.xxs};
    margin-bottom: 0px;
  }

  #faxTitle {
    margin-top: ${({ theme }) => theme.spacing.xxs};
    margin-bottom: 0px;
  }

  ${ColumnContent} + ${ColumnTitle} {
    margin-top: ${({ theme }) => theme.rem(40)};
  }
`

export const ColumnAppLink = styled.a`
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
  height: ${({ theme }) => theme.rem(40)};

  svg {
    width: ${({ theme }) => theme.rem(132)};
    height: ${({ theme }) => theme.rem(40)};
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
`

export const ColumnSocialMediaList = styled.div`
  display: flex;
  align-items: center;
  width: ${({ theme }) => theme.rem(128)};
  flex-wrap: wrap;

  svg {
    margin-right: ${({ theme }) => theme.spacing.xxs};
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
  }
`

export const ColumnLink = styled.a`
  ${({ theme }) => theme.font(12, 18)};
  color: ${({ theme }) => theme.colors.white(500)};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
  text-decoration: none;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
`

export const ColumnItem = styled.span`
  ${({ theme }) => theme.font(12, 18)};
  color: ${({ theme }) => theme.colors.white(500)};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};

  &:last-child {
    margin-bottom: 0;
  }

  p {
    all: inherit;
    margin-bottom: 0;
  }
`
