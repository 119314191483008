import { axios } from 'services/axios'
import { BACKEND_SHIPPING_ZIPCODE } from 'settings/kernel'

import { axiosException } from '../exception/axios'

export const consultZipCode = async (zipCode) => {
  try {
    const { data } = await axios.get(
      `${BACKEND_SHIPPING_ZIPCODE}?zip_code=${zipCode}`
    )

    return data
  } catch (error) {
    throw axiosException(error, 'GET CONSULT ZIPCODE')
  }
}
