import { useEffect, useState } from 'react'

import { AccountMenuDrawer } from 'components/Header/ProfileInfo/AccountMenuDrawer'
import { useHeaderContext } from '../Header.context'
import * as S from './ProfileInfo.styles'

export default function ProfileInfo() {
  const { clientId, clientName } = useHeaderContext()
  const [loginUrl, setLoginUrl] = useState('login')

  useEffect(() => {
    const currentUrl = window.location.href
    if (currentUrl) setLoginUrl(`/login?redirect_uri=${currentUrl}`)
  }, [])

  if (!clientName || clientName === '' || !clientId || clientId === '') {
    return (
      <S.Container id='blocoInformacoesCliente'>
        <span>
          <S.ProfileLinkBold id='linkLoginHeader' href={loginUrl}>
            ENTRE
          </S.ProfileLinkBold>{' '}
          ou <br />
          <S.ProfileLinkBold id='linkCadastroHeader' href='/cadastro'>
            CADASTRE-SE
          </S.ProfileLinkBold>
        </span>
      </S.Container>
    )
  }

  return (
    <S.Container id='blocoInformacoesCliente'>
      <S.ClientName>Olá, {clientName}</S.ClientName>
      <S.ProfileRow>
        <AccountMenuDrawer />
      </S.ProfileRow>
    </S.Container>
  )
}
