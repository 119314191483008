import { useUser } from '@k_frontend/core'
import { useMutation } from '@tanstack/react-query'
import { useAttendanceStore } from 'components/PageMyAccount/Attendance/Page/Attendance.store'
import { useState } from 'react'
import { Login } from 'services/login/login'
import { shallow } from 'zustand/shallow'

export function useAccountMenuDrawer() {
  const { session, clientId } = useUser()
  const [isHover, setIsHover] = useState(false)

  const { resetAttendanceStore } = useAttendanceStore(
    (state) => ({
      resetAttendanceStore: state.resetAttendanceStore
    }),
    shallow
  )

  const { mutateAsync: handleLogout } = useMutation({
    mutationFn: async () => {
      const serviceLogin = new Login()

      return await serviceLogin.doLogout({
        customerId: clientId,
        customerSession: session
      })
    },
    onSuccess(data) {
      if (data) {
        resetAttendanceStore(0)
        window.location.href = '/'
      }
    }
  })

  return {
    isHover,
    setIsHover,
    handleLogout
  }
}
