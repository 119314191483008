import { VWO_ACCOUNT_ID, VWO_SDK_ENVIRONMENT } from 'settings/kernel'
import vwoSdk from 'vwo-node-sdk'

export class FeatureFlag {
  private static instance: FeatureFlag
  private vwoClientInstance: any

  private constructor() {
    this.initializeVwoSdk()
  }

  static async getInstance(): Promise<FeatureFlag> {
    if (!FeatureFlag.instance) {
      FeatureFlag.instance = new FeatureFlag()
    }
    await FeatureFlag.instance.initializeVwoSdkAsync()
    return FeatureFlag.instance
  }

  private async initializeVwoSdkAsync() {
    const settingsFile = await vwoSdk.getSettingsFile(
      VWO_ACCOUNT_ID,
      VWO_SDK_ENVIRONMENT
    )

    this.vwoClientInstance = vwoSdk.launch({
      settingsFile
    })
  }

  private initializeVwoSdk() {
    this.initializeVwoSdkAsync()
  }

  async isFeatureEnabled(campaign: string, userId: string): Promise<boolean> {
    await this.initializeVwoSdkAsync()
    return this.vwoClientInstance.isFeatureEnabled(campaign, userId)
  }

  async testeAb(campaign: string, userId: string): Promise<string> {
    await this.initializeVwoSdkAsync()
    return this.vwoClientInstance.activate(campaign, userId)
  }

  async testeTrack(
    campaignKey: string,
    userId: string,
    hypothesis: string,
    options: unknown
  ): Promise<any> {
    await this.initializeVwoSdkAsync()
    return this.vwoClientInstance.track(
      campaignKey,
      userId,
      hypothesis,
      options
    )
  }
}
