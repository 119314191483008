function workWithUs() {
  console.log(`

   _  __     ____        __  __ _
  | |/ /__ _| __ ) _   _|  \\/  | |
  | ' // _\` |  _ \\| | | | |\\/| | |____
  | . \\ (_| | |_) | |_| | |  | |_|\\ \\ \\
  |_|\\_\\__,_|____/ \\__,_|_|  |_(_)/_/_/

  Trabalhe conosco, veja as vagas em:
  https://carreiras.magazineluiza.com.br/times/KaBuM!/


`)
}

export default workWithUs
