import { NextApiRequest, NextApiResponse } from 'next/types'

import { getCookie, setCookie } from 'cookies-next'
import { DESKTOP_STORE, MOBILE_STORE } from 'settings/kernel'

export function handleStore(options?: {
  req: NextApiRequest
  res: NextApiResponse
}): string {
  const isMobileDevice = getCookie('isMobileDevice', options || {})

  if (isMobileDevice === 'true') {
    return MOBILE_STORE
  }
  return DESKTOP_STORE
}

export function handleCacheStore(req, res) {
  let isMobileScreen = req?.cookies.isMobile

  const isMobileDevice =
    req?.headers['cloudfront-is-mobile-viewer'] === 'true' ||
    req?.headers['cloudfront-is-tablet-viewer'] === 'true'

  if (typeof isMobileScreen === 'undefined') {
    isMobileScreen = isMobileDevice
  } else {
    isMobileScreen = isMobileScreen === 'true'
  }
  setCookie('isMobile', isMobileScreen.toString(), { req, res })
  setCookie('isMobileDevice', isMobileDevice.toString(), { req, res })
}
