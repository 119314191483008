import { ToastCard } from '.'

export function ToastsContainer({ toasts }) {
  return (
    <div className='flex flex-col-reverse gap-12 fixed z-10 right-0 desktop:top-[7rem]'>
      {toasts.map((toast) => (
        <ToastCard key={toast.id} {...toast} />
      ))}
    </div>
  )
}
