import { notify } from 'react-notify-toast'

import CookiesInterface from 'interfaces/cookies'
import { call, put } from 'redux-saga/effects'
import { addProductCart } from 'services/cart'
import { Creators as CartCreators } from 'state/ducks/cart'
import Cookie from 'universal-cookie'
import { addProductToCookies } from 'utils/cart'

function* addProductToCart(action) {
  const cookie = new Cookie()
  const isMobile = cookie.get('isMobile')
  const { addType } = action
  let { products } = action

  if (!Array.isArray(products)) products = [products]

  const ClassCookiesInterface = new CookiesInterface()
  const clientId = ClassCookiesInterface.getClientId()
  const session = ClassCookiesInterface.getSession()

  const loadingProducts = products.map((product) => ({
    code: product.code,
    tagCode: product.tagCode,
    offerIdMarketplace: product.offerIdMarketplace
  }))

  yield put(CartCreators.setBtnAddCartLoading(loadingProducts, addType))

  const payload = {
    produtos: products.map((product) => ({
      codigo: product.code,
      quantidade: 1,
      di: product?.di || product?.offer?.hashCode,
      oferta: !!product.offer && Object.keys(product.offer).length > 0,
      mercadoria_codigo: parseInt(product.tagCode, 0),
      id_marketplace: product.offerIdMarketplace
    })),
    session,
    id_cliente: clientId
  }

  const data = yield call(addProductCart, payload)

  if (data.success) {
    const { cart } = data
    cart.forEach((item) => {
      addProductToCookies(item)
    })

    const [product] = cart

    // TODO - Redirecionar para o pré-carrinho
    if (isMobile) {
      window.location.href = `/carrinho`
    } else {
      window.location.href = `/precarrinho/${product.codigo}/${product.mercadoria_codigo}`
    }
  } else {
    if (
      data.errorType === 'NOT_LOGGED' ||
      data.errorType === 'SESSION_EXPIRED'
    ) {
      window.location.href = `/login`
    } else if (data.errorType === 'UNAVAILABLE') {
      window.location.href = `/limite-excedido?type=oferta`
    } else if (data.errorType === 'UNAVAILABLE_PRIME') {
      window.location.href = `/limite-excedido?type=prime`
    } else if (
      data.errorType === 'OUT_OF_STOCK' &&
      payload.produtos[0].oferta
    ) {
      window.location.href = `/oferta-esgotada`
    } else {
      notify.show('Ocorreu um erro ao adicionar o produto no carrinho', 'error')
    }
  }

  yield put(CartCreators.setBtnAddCartLoading(loadingProducts))
}

export default {
  addProductToCart
}
