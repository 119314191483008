export const addToCartResponse = (data) => {
  return {
    success: data.sucesso,
    cart: data.carrinho || [],
    errorType: data.erro_tipo
  }
}

export const addToCartErrorResponse = () => {
  return {
    success: false,
    errorType: 'DEFAULT'
  }
}
