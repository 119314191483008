import { dataLayerEventAddToCart } from '@k_frontend/core'
import { addToCartById } from 'utils/addToCartById'
import { ActionAddToCart } from 'utils/addToCartById/addToCartById.types'

export function exportAddToCart(
  dispatch: (arg: ActionAddToCart) => ActionAddToCart
) {
  window.addToCartSmartHint = (productId, recommendationTitle) =>
    addToCartById(
      productId,
      recommendationTitle,
      dataLayerEventAddToCart,
      dispatch
    )
}
