import { useUser } from '@k_frontend/core'
import { useQuery } from '@tanstack/react-query'
import { useServicesStatus } from 'hooks/useServiceStatus'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Notice } from 'services/notice'
import { MILISECOND, THIRTY_MINUTES_TO_MILISECOND } from 'settings/constants'
import { useNotificationsStore } from './NotificationsContainer.store'

export function useNotificationsContainer() {
  const { clientId: customerId, session: customerSession } = useUser()
  const { servicesStatus } = useServicesStatus()
  const { setIsOpenNotifications, setNoticeQuantity } = useNotificationsStore()
  const { push } = useRouter()
  const [isRead] = useState(0)
  const [page] = useState(1)

  const requestParams = useMemo(
    () => ({
      customerId,
      customerSession,
      isRead,
      page
    }),
    [customerId, customerSession, isRead, page]
  )

  const hasCustomerId = useMemo(() => {
    if (customerId) return true
    return false
  }, [customerId])

  const {
    data: allCustomerNotices,
    isError: allNoticesError,
    isLoading: allNoticesLoading
    // refetch: allNoticesRefetch
  } = useQuery({
    queryKey: ['getAllCustomerNotices', requestParams],
    queryFn: async () => {
      const serviceNotice = new Notice()
      return await serviceNotice.GetAllCustomerNotices({ ...requestParams })
    },
    enabled: hasCustomerId && servicesStatus?.enable_notice,
    staleTime: MILISECOND * 2,
    // refetch query for each 30 minutes
    refetchInterval: THIRTY_MINUTES_TO_MILISECOND
  })

  const onButtonClickAction = useCallback(() => {
    push('/minha-conta/notificacoes')
    setIsOpenNotifications(false)
  }, [push])

  const shouldRenderAlert = useMemo(() => {
    return (
      allNoticesError ||
      allCustomerNotices?.notifications?.length === 0 ||
      !servicesStatus?.enable_notice
    )
  }, [allNoticesError, allCustomerNotices, servicesStatus])

  useEffect(() => {
    const hasNextPage = allCustomerNotices?.nextPage
    const noticesQuantity = (
      allCustomerNotices?.notifications?.length ?? 0
    ).toString()

    if (hasNextPage) {
      setNoticeQuantity('8+')
    } else {
      setNoticeQuantity(noticesQuantity)
    }
  }, [allCustomerNotices])

  return {
    allCustomerNotices,
    allNoticesError,
    allNoticesLoading,
    shouldRenderAlert,
    onButtonClickAction
  }
}
