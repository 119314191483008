import { IconCollapseHeader } from '@k_frontend/icons'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import * as S from '../ProfileInfo.styles'
import { MY_ACCOUNT_SHORTCUTS } from './AccountMenuDrawer.constant'
import { useAccountMenuDrawer } from './AccountMenuDrawer.hook'

export function AccountMenuDrawer() {
  const { handleLogout, setIsHover, isHover } = useAccountMenuDrawer()

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <DropdownMenu.Root open={isHover} onOpenChange={setIsHover} modal={false}>
        <DropdownMenu.Trigger asChild>
          <div className={S.ContainterSVG({ rotate: isHover })}>
            <a
              href='/minha-conta'
              id='linkMinhaContaHeader'
              className='flex w-full items-center text-xs text-black-400 text-nowrap cursor-pointer'
            >
              MINHA CONTA <IconCollapseHeader width='12' height='12' />
            </a>
          </div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content className='min-w-[220px] bg-white rounded-4 p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-[9999]'>
            {MY_ACCOUNT_SHORTCUTS.map((menu) => (
              <a
                href={menu.slug}
                className='hover:bg-black-400 border-b border-black-600 rounded-4'
                key={menu.id}
              >
                <DropdownMenu.Item className='px-8 py-12 hover:bg-black-400 border-b border-black-600 rounded-4'>
                  {<menu.icon width='12' height='12' color='#ff6500' />}
                  <span className='text-sm text-black-700 ml-8'>
                    {menu.label}
                  </span>
                </DropdownMenu.Item>
              </a>
            ))}
            <DropdownMenu.Item
              className='px-8 py-12 hover:bg-black-400 border-b border-black-600 rounded-4 cursor-pointer'
              key={'menu-to-logout'}
              onClick={() => handleLogout()}
            >
              <span className='text-sm text-black-700 ml-8'>SAIR </span>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  )
}
