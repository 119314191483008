import { datadogRum } from '@datadog/browser-rum'
import { useUser } from '@k_frontend/core'
import { ENVIRONMENT } from 'settings/kernel'
import packageJson from '../../../package.json'

export default function ddRum() {
  if (ENVIRONMENT !== 'local') {
    const { clientId, session, isPrime, clientName } = useUser.getState()

    datadogRum.init({
      applicationId: '108aad78-6f5d-4bc0-bda2-3ecff672a7f3',
      clientToken: 'pube945ea01e482f1637a2da9310001296b',
      site: 'datadoghq.com',
      service: 'ecommerce-frontend-account',
      env: ENVIRONMENT,
      version: packageJson.version,
      sessionSampleRate: ENVIRONMENT === 'stg' ? 100 : 0.25,
      sessionReplaySampleRate: 5,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      allowedTracingUrls: [
        /https:\/\/.*\.grupokabum\.com\.br\/.*/,
        /https:\/\/static\.kabum(dev)?\.com\.br\/.*/
      ],
      traceSampleRate: 20,
      traceContextInjection: 'sampled'
    })
    if (clientId && session) {
      datadogRum.setUser({
        id: clientId,
        name: clientName,
        email: clientId,
        session,
        isPrime
      })
    }
  }
}
