import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { SelectAddressStore } from './SelectAddress.type'

export const useSelectAddress = ({
  addressSelect
}: { addressSelect: string }) => {
  const useSelectAddressStore = create<SelectAddressStore>()(
    persist(
      (set) => ({
        showAddress: true,
        setShowAddress: (value: boolean) =>
          set({
            showAddress: value
          })
      }),
      {
        name: 'kbm_user_show_address'
      }
    )
  )

  const firstAddressWord = addressSelect.split(' ').shift()
  const remainderAddress = addressSelect.split(' ').slice(1).join(' ')

  return {
    useSelectAddressStore,
    firstAddressWord,
    remainderAddress
  }
}
