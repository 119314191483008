export const LG = {
  LOGIN: 'lg/v1/login',
  OAUTH: 'lg/v1/login/oauth',
  APPLE_CALLBACK: 'lg/apple/oauth/callback',
  SESSION_LOGOUT: 'lg/v1/session/logout'
}

export const NOTIFICATIONS = {
  MFA_TRIES: 'notifications/v2/message/activation/tries',
  ACTIVATION: 'notifications/v3/message/activation',
  MFA_TRIES_RECOVERY_PASSWORD: 'notifications/v1/password/recovery/tries',
  PASSWORD_RECOVERY: 'notifications/v2/password/recovery',
  SEND_MFA_TO_USER: 'notifications/v2/message/mfa'
}

export const CUSTOMER = {
  ACCOUNT_ACTIVATE: 'customer/v2/account/activate',
  RECOVERY_PASSWORD: 'customer/v1/account/recovery',
  CUSTOMER_DATA: 'customer/v1/account',
  DELIVERY_PROOF_LIST: 'customer/v2/proof-of-delivery/list',
  ORDER_DETAILS: 'customer/v2/order',
  WISHLIST_DATA: 'customer/v1/wishlist',
  AGREEMENT_ADS: 'customer/v1/account/ads'
}

export const UPLOAD = {
  ACCOUNT_UPLOAD_IMAGE_PROFILE: 'upload/v1/upload/profile'
}

export const NOTICE = {
  NOTIFICATIONS: 'notice/v1/notifications',
  NOTIFICATION: 'notice/v1/notification',
  HEALTH_CHECK: 'notice/healthcheck/'
}

export const RMA_GENESYS = {
  CONVERSATION: 'ecommerce-rma/v1/genesys/conversation',
  OPTIONS: 'ecommerce-rma/v1/genesys/options',
  ORDER: 'ecommerce-rma/v1/genesys/order'
}

export const BACKEND_RMA_PROTOCOL = 'ecommerce-rma/v1/protocols'
export const BACKEND_CUSTOMER_EVALUATION = `customer/v1/review`

export const CATALOG = {
  PRODUCT: 'catalog/v2/products'
}
