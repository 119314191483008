import { ResizeHOC } from '@k_frontend/core'
import { Container } from '@k_frontend/ui'

import * as S from './FooterLinks.styles'
import { TypeFooterLinksProps } from './FooterLinks.types'
import { FooterLinksDesktop } from './FooterLinksDesktop'
import { FooterLinksMobile } from './FooterLinksMobile'

export default function FooterLinks({
  isPrime,
  kernel,
  openDepartments,
  footerJson
}: TypeFooterLinksProps) {
  return (
    <S.LinksContainer
      isPrime={isPrime}
      id='linksFooter'
      data-testid='footerLinks'
    >
      <Container>
        <ResizeHOC<TypeFooterLinksProps>
          MobileComponent={FooterLinksMobile}
          DesktopComponent={FooterLinksDesktop}
          kernel={kernel}
          isPrime={isPrime}
          openDepartments={openDepartments}
          footerJson={footerJson}
        />
      </Container>
    </S.LinksContainer>
  )
}
