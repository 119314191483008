import * as serverAxios from 'axios'
import { consultZipCode } from 'services/addresses'

export const currency = (value) => {
  if (!isNaN(parseInt(value))) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value)
  }

  return null
}

export const cpfMask = (cpf = '') => {
  return cpf
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

const mask = (value, pattern) => {
  let i = 0
  const v = value.toString()

  return pattern.replace(/#/g, () => v[i++] || '')
}

const maskCelphone = (value) => {
  return mask(value, '(##) #####-####')
}

const maskTelephone = (value) => {
  return mask(value, '(##) ####-####')
}

export const selectCorrectPhoneMask = (phone) => {
  if (phone && phone.length === 11) {
    return maskCelphone(phone)
  }
  if (phone && phone.length === 10) {
    return maskTelephone(phone)
  }
  return ''
}

export const cepMask = (cep = '') => {
  let masked = ''
  for (let i = 0; i < cep.length; i++) {
    if (i === 5 && cep[i] !== '-') {
      masked += '-' + cep.charAt(i)
    } else {
      masked += cep.charAt(i)
    }
  }
  return masked
}

export const dateFormatting = (date) => {
  const data = new Date(date * 1000) // Converting seconds to milliseconds

  const day = data.getDate().toString().padStart(2, '0')
  const month = (data.getMonth() + 1).toString().padStart(2, '0')
  const year = data.getFullYear()

  return `${day}/${month}/${year}`
}

export const isValidDate = (date = '') => {
  const dateSplitted = date.split('/')
  const ISODate = `${dateSplitted[2]}-${dateSplitted[1]}-${dateSplitted[0]}`

  if (new Date(Date.parse(ISODate)).toString() === 'Invalid Date') {
    return false
  }

  if (Number(dateSplitted[2]) < 1900) {
    return false
  }

  if (new Date(Date.parse(ISODate)) > new Date()) {
    return false
  }

  return true
}

export const isOverThirteenYearsOld = (date = '') => {
  const dateSplitted = date.split('/')
  const ISODate = `${dateSplitted[2]}/${dateSplitted[1]}/${dateSplitted[0]}`
  const miliSecondsInAYear = 3.15576e10

  if (
    Math.floor(
      (new Date() - new Date(ISODate).getTime()) / miliSecondsInAYear
    ) < 13
  ) {
    return false
  }

  return true
}

const isValidCNPJ = (cnpj = '') => {
  // Referencia -> https://www.geradorcnpj.com/javascript-validar-cnpj.htm

  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj === '') return false

  if (cnpj.length !== 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado.toString() !== digitos.charAt(0)) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado.toString() !== digitos.charAt(1)) return false

  return true
}

const isValidCPF = (cpf = '') => {
  if (
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }

  let add
  // 1st digit
  add = 0
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i)) * (10 - i)
  }
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf.charAt(9))) {
    return false
  }

  // 2nd digit
  add = 0
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i)) * (11 - i)
  }
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf.charAt(10))) {
    return false
  }

  return true
}

export const sanitizeCookies = (cookieString) => {
  return cookieString?.replace(/[()<>;]/g, '') || ''
}

export const sanitizeInputValue = (value) => {
  return value?.replace(/</g, '').replace(/>/g, '')
}

export const cpfOrCpnjMask = (field = '') => {
  // Remove tudo o que não é dígito
  field = field.replace(/\D/g, '')

  if (field.length <= 13) {
    // CPF

    // Coloca um ponto entre o terceiro e o quarto dígitos
    field = field.replace(/(\d{3})(\d)/, '$1.$2')

    // Coloca um ponto entre o terceiro e o quarto dígitos
    // de novo (para o segundo bloco de números)
    field = field.replace(/(\d{3})(\d)/, '$1.$2')

    // Coloca um hífen entre o terceiro e o quarto dígitos
    field = field.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    // CNPJ

    // Coloca ponto entre o segundo e o terceiro dígitos
    field = field.replace(/^(\d{2})(\d)/, '$1.$2')

    // Coloca ponto entre o quinto e o sexto dígitos
    field = field.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')

    // Coloca uma barra entre o oitavo e o nono dígitos
    field = field.replace(/\.(\d{3})(\d)/, '.$1/$2')

    // Coloca um hífen depois do bloco de quatro dígitos
    field = field.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return field
}

export const cnpjMask = (field = '') => {
  // CNPJ

  // Coloca ponto entre o segundo e o terceiro dígitos
  field = field.replace(/^(\d{2})(\d)/, '$1.$2')

  // Coloca ponto entre o quinto e o sexto dígitos
  field = field.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')

  // Coloca uma barra entre o oitavo e o nono dígitos
  field = field.replace(/\.(\d{3})(\d)/, '.$1/$2')

  // Coloca um hífen depois do bloco de quatro dígitos
  field = field.replace(/(\d{4})(\d)/, '$1-$2')

  return field
}

export const removeCpfMask = (field) => {
  return field?.replace(/(\.|\/|-)/g, '')
}

export const removeCepMask = (field) => {
  return field?.replace(/(\.|\/|-)/g, '')
}

export const removePhoneMask = (field) => {
  return field?.replace(/\D/g, '')
}

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validateName = (name) => {
  const hasMinimumLength = name.length > 5
  const hasSpace = name.indexOf(' ') > 0
  const lastCharIsSpace = name.charAt(name.length - 1) === ' '

  return hasMinimumLength && hasSpace && !lastCharIsSpace
}

export const timestampToDatestring = (timestamp) => {
  const date = new Date(Number(timestamp) * 1000)

  return date.toLocaleDateString('pt-BR')
}

export const timestampToWrittenDate = (timestamp) => {
  if (!timestamp) return ''
  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]
  const date = new Date(Number(timestamp) * 1000)
  return (
    date.getDate() +
    ' de ' +
    meses[date.getMonth()] +
    ' às ' +
    date.getHours() +
    'h' +
    String(date.getMinutes()).padStart(2, '0')
  )
}

export const isDocumentValid = (document) => {
  const unmasked = removeCpfMask(document)
  const isCPF = unmasked.length === 11
  const isCNPJ = unmasked.length === 14

  if (isCPF) {
    return isValidCPF(unmasked)
  } else if (isCNPJ) {
    return isValidCNPJ(unmasked)
  } else {
    return false
  }
}

const getYearList = (fullYear = false) => {
  const currentYear = new Date().getFullYear()
  const yearsList = []

  if (fullYear) {
    for (let index = 1920; index <= currentYear; index++) {
      yearsList.push(index.toString())
    }

    return yearsList.reverse()
  } else {
    const yearTemp = parseInt(currentYear.toString().substring(2, 4))

    for (let index = yearTemp; index < yearTemp + 13; index++) {
      yearsList.push(index.toString())
    }

    return yearsList
  }
}

export const checkSessionValidity = async (response) => {
  const { msg } = response

  if (msg === 'Sua sessão expirou, faça o login novamente') {
    logoutMyAccount()
  }
}

const logoutMyAccount = async () => {
  await serverAxios.post('/api/account/logout')
  window.location.href = '/login'
}

export function converFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const passwordHasValidChars = (password) => {
  const invalidCharsRegex = /^[0-9A-Za-z.(),@º&*=./?^!#$]{0,32}$/
  return invalidCharsRegex.test(password)
}

export const getAddressValidatingFields = async (zipCode) => {
  try {
    const { address } = await consultZipCode(zipCode)
    if (address) {
      return {
        bairro: address.district,
        cidade: address.city,
        estado: address.state,
        endereco: address.street,
        isValid: true
      }
    }
    return {}
  } catch (error) {
    throw new Error(error)
  }
}
