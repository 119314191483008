import { WalletCardsResponse } from 'services/wallet/v1/cards.types'

import { WalletCardsModel } from './cards.types'

export function walletCardsModel(data: WalletCardsResponse): WalletCardsModel {
  return {
    cardList: data.card_list.map((card) => ({
      brand: card.card_brand,
      expDate: card.card_exp_date,
      referenceKey: card.card_reference_key,
      main: card.main_card,
      nickname: card.card_nick_name,
      number: card.card_number,
      status: card.status,
      token: card.card_token,
      expired: card.expired
    }))
  }
}
