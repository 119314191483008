import { IconClose } from '@k_frontend/icons'

import { useMemo } from 'react'
import { TOAST_TYPES } from './ToastCard.constants'
import { useToastCard } from './ToastCard.hook'
import { ToastTimerBar, ToastWrapper } from './ToastCard.styles'

export function ToastCard({ message, type, id }) {
  const {
    handleDismiss,
    handleMouseEnter,
    handleMouseLeave,
    progressRef,
    dismissed
  } = useToastCard({ toastId: id })
  const { icon, defaultToastColor } = useMemo(() => {
    const { icon, defaultToastColor } = TOAST_TYPES[type]
    return { icon, defaultToastColor }
  }, [type])

  return (
    <div
      className={ToastWrapper({
        colors: defaultToastColor,
        desmissed: dismissed
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='flex items-center p-16 w-full relative mr-16'>
        <span className='mr-8'>{icon}</span>
        <p className='text-sm font-medium text-black-800'>{message}</p>
        <button
          className='absolute right-8 top-0'
          type='button'
          onClick={handleDismiss}
        >
          <IconClose width='16' height='16' color='#aeb0d7' />
        </button>
      </div>
      <div className='absolute bottom-0 left-2 w-full h-4 bg-black-300'>
        <div
          className={ToastTimerBar({
            colors: defaultToastColor
          })}
          ref={progressRef}
        ></div>
      </div>
    </div>
  )
}
