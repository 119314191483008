import { getCookie, setCookie } from 'cookies-next'
import { FeatureFlag } from 'utils/featureFlag/featureFlag'

export async function setSearchVariation(session: string) {
  const init = await FeatureFlag.getInstance()
  const searchVariation = await init.isFeatureEnabled(
    'campaign_busca_smarthint',
    session
  )
  setCookie('searchVariation', searchVariation ? 'smarthint' : 'catalog', {
    maxAge: 600
  })
}

export function getSearchVariation() {
  return getCookie('searchVariation')
}
