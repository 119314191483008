import { getCookie, setCookie } from 'cookies-next'
import { FeatureFlag } from 'utils/featureFlag/featureFlag'

export function useDeliveryProof() {
  async function setDeliveryProof(session: string) {
    const init = await FeatureFlag.getInstance()
    const deliveryProofProtocol = await init.isFeatureEnabled(
      'campaign_deliver_receipt',
      session
    )
    setCookie('deliveryProofProtocol', deliveryProofProtocol ?? false, {
      maxAge: 600
    })
  }

  function getDeliveryProof() {
    return getCookie('deliveryProofProtocol')
  }

  return {
    setDeliveryProof,
    getDeliveryProof
  }
}
