import { useEffect } from 'react'

import { IconCollapseHeader } from '@k_frontend/icons'
import ThemeDefault from 'theme'

import { MenuLinksModalProps } from './FooterLinks.types'
import * as S from './MenuLinksModal.styles'

export function MenuLinksModal({
  linksList,
  isOpenModal,
  setIsOpenModal,
  title
}: MenuLinksModalProps) {
  useEffect(() => {
    const html = document?.querySelector('html')
    const body = document?.querySelector('body')
    const header = document?.querySelector('#header') as HTMLElement
    if (html?.style) {
      html.style.overflow = isOpenModal ? 'hidden' : 'unset'
    }
    if (body?.style) {
      html.style.overflow = isOpenModal ? 'hidden' : 'unset'
    }
    if (header?.style) {
      header.style.position = isOpenModal ? 'fixed' : 'relative'
    }

    return () => {
      if (html?.style) {
        html.style.overflow = 'unset'
      }
      if (body?.style) {
        html.style.overflow = 'unset'
      }
      if (header?.style) {
        header.style.position = 'relative'
      }
    }
  }, [isOpenModal])

  return (
    <>
      <S.Wrapper data-testid='MenuLinksModal'>
        <S.Header
          onClick={() => {
            setIsOpenModal(false)
          }}
        >
          <button>
            <IconCollapseHeader
              width={ThemeDefault.spacing.xs}
              height={ThemeDefault.spacing.xs}
              color={ThemeDefault.colors.secondary(500)}
            />
          </button>
          <S.Title> {title}</S.Title>
        </S.Header>
        <S.List>
          {linksList?.map((item, index) => (
            <S.ItemList
              className='linksList'
              key={index}
              href={item.link}
              target={item.target}
              rel={item?.rel}
            >
              {item.title}
              <IconCollapseHeader
                width={ThemeDefault.spacing.xs}
                height={ThemeDefault.spacing.xs}
                color={ThemeDefault.colors.black(700)}
                onClick={() => {
                  setIsOpenModal(false)
                }}
              />
            </S.ItemList>
          ))}
        </S.List>
      </S.Wrapper>
    </>
  )
}
