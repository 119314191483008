export const SITE_TITLE =
  'KaBuM! | Maior E-commerce de Tecnologia e Games da América Latina'

export const SITE_TAG_DESCRIPTION =
  'Encontre as melhores ofertas em Tecnologia e Games aqui no KaBuM!. Confira nossa linha de Hardware, Smartphones, Computadores, Eletrônicos e mais. Acesse!'

export const SITE_TAG_TITLE =
  'KaBuM! | Maior E-commerce de Tecnologia e Games da América Latina'

export const SITE_TAG_KEYWORDS =
  'kabum, kabum loja, loja online, kabum referencias, kabum opinioes, kabum alguem ja comprou, kabum é boa, kabum seguro, kabum é confiavel, kabum.com.br, www.kabum.com.br, informatica, loja on-line, hardware'
