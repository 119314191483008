import { hc } from 'services/customer/hc'
import { Notice } from 'services/notice'
import { getWalletHealthCheck } from 'services/wallet/v1/cards'
import { create } from 'zustand'

import { ServiceStatusProps } from './useServiceStatus.types'

const useServicesStatus = create<ServiceStatusProps>()((set) => ({
  servicesStatus: {
    enable_rma: false,
    enable_product_filter: false,
    enable_cobranded_responsive: false,
    enable_facebook_signup: false,
    enable_recaptcha: false,
    enable_score_tmx: false,
    enable_gift_card: false,
    enable_google_signup: false,
    enable_recaptcha_v3_register: false,
    enable_apple_signup: false,
    enable_message_3p: undefined,
    enable_black_friday: false,
    enable_support_email: false,
    enable_review: false,
    enable_user_activation: false,
    enable_cobranded: false,
    enable_help_order: false,
    enable_mfa: false,
    enable_forum: false,
    enable_signin_apple: false,
    enable_signin_facebook: false,
    enable_signin_google: false,
    enable_notice: false,
    enable_card_register: false
  },
  verifyServicesStatus: async () => {
    try {
      const response = await hc()
      set((state) => ({
        servicesStatus: {
          ...state.servicesStatus,
          ...response
        }
      }))
    } catch (_error) {
      set((state) => ({
        servicesStatus: {
          ...state.servicesStatus,
          enable_rma: false,
          enable_product_filter: false,
          enable_cobranded_responsive: false,
          enable_facebook_signup: false,
          enable_recaptcha: false,
          enable_score_tmx: false,
          enable_gift_card: false,
          enable_google_signup: false,
          enable_recaptcha_v3_register: false,
          enable_apple_signup: false,
          enable_message_3p: false,
          enable_black_friday: false,
          enable_support_email: false,
          enable_review: false,
          enable_user_activation: false,
          enable_cobranded: false,
          enable_help_order: false,
          enable_mfa: false,
          enable_forum: false,
          enable_signin_apple: false,
          enable_signin_facebook: false,
          enable_signin_google: false,
          enable_notice: false
        }
      }))
    }
  },
  verifyNoticeStatus: async () => {
    try {
      const serviceNotice = new Notice()
      const data = await serviceNotice.GetNoticeHealthCheck()
      set((state) => ({
        servicesStatus: {
          ...state.servicesStatus,
          enable_notice: data.notification_feature_status
        }
      }))
    } catch (_error) {
      set((state) => ({
        servicesStatus: { ...state.servicesStatus, enable_notice: false }
      }))
    }
  },
  verifyWalletStatus: async () => {
    try {
      const data = await getWalletHealthCheck()
      set((state) => ({
        servicesStatus: {
          ...state.servicesStatus,
          enable_card_register: data.enable_card_register
        }
      }))
    } catch (_error) {
      set((state) => ({
        servicesStatus: { ...state.servicesStatus, enable_card_register: false }
      }))
    }
  }
}))

export { useServicesStatus }
