import { addToCartErrorResponse, addToCartResponse } from 'models/cart'
import { BACKEND_ADICIONAR_CARRINHO } from 'settings/kernel'
import { axios } from './axios'

export const addProductCart = async (payload) => {
  try {
    const { data } = await axios.post(`${BACKEND_ADICIONAR_CARRINHO}`, payload)
    return addToCartResponse(data)
  } catch (_error) {
    addToCartErrorResponse()
  }
}
