import Skeleton from 'react-loading-skeleton'

import { pushToDataLayer } from '@k_frontend/core'

import DropdownColumn from '../DropdownColumn'
import * as S from './DepartmentsMenu.styles'
import { DesktopDepartmentsMenuProps } from './DepartmentsMenu.types'

export default function DepartmentsMenuDesktop({
  menuTimeout,
  departments,
  sections,
  categories,
  currentDepartment,
  currentSection,
  handleEnterDepartment,
  handleEnterSection,
  handleDisableMenu,
  hrefPrefix,
  bannerData,
  bannerRef,
  isLoading,
  dataLayerBannerClick
}: DesktopDepartmentsMenuProps) {
  function renderBanner() {
    const banner = bannerData?.departamentos?.[0]

    if (isLoading)
      return (
        <S.Column bannerColumn={true}>
          <S.BannerSkeleton>
            <Skeleton height={474} count={1} />
          </S.BannerSkeleton>
        </S.Column>
      )

    if (!banner) return null

    return (
      <S.Column ref={bannerRef} bannerColumn={true}>
        <S.Redirect
          onClick={dataLayerBannerClick}
          href={banner?.destinationUrl}
          target='_blank'
        >
          <S.BannerImage src={banner?.mediaUrl} alt={banner?.alt} />
        </S.Redirect>
      </S.Column>
    )
  }

  function showDepartmentLink(hrefPrefix: string) {
    return (
      <S.RedirectLink href={`${hrefPrefix}/${currentDepartment.path}`}>
        Ver tudo de {currentDepartment.name}
      </S.RedirectLink>
    )
  }

  function showSectionLink(hrefPrefix: string) {
    return (
      <S.RedirectLink href={`${hrefPrefix}/${currentSection.path}`}>
        Ver tudo de {currentSection.name}
      </S.RedirectLink>
    )
  }

  function getSectionColumn() {
    const showColumn = sections.length > 0
    const redirectLink = showDepartmentLink(hrefPrefix)

    if (!showColumn) return null
    return (
      <S.Column data-testid='sections-column'>
        <DropdownColumn
          list={sections}
          handleOpenMenu={handleEnterSection}
          handleMouseLeave={() => clearTimeout(menuTimeout)}
          onClick={(item) => {
            pushToDataLayer({
              click_category: `${currentDepartment.name}/${item.name}`
            })
          }}
          hrefPrefix={`${hrefPrefix}/${currentDepartment.path}`}
          showRedirectLink={redirectLink}
        />
      </S.Column>
    )
  }

  function getCategoryColumn() {
    const showColumn = categories.length > 0
    const redirectLink = showSectionLink(hrefPrefix)

    if (!showColumn) return null
    return (
      <S.Column data-testid='categories-column'>
        <DropdownColumn
          list={categories}
          handleMouseLeave={() => clearTimeout(menuTimeout)}
          onClick={(item) => {
            pushToDataLayer({
              click_category: `${currentDepartment.name}/${currentSection.name}/${item.name}`
            })
            window.location.href = `${hrefPrefix}/${currentSection.path}/${item.slug}`
          }}
          hrefPrefix={`${hrefPrefix}/${currentSection.path}`}
          showRedirectLink={redirectLink}
          hideArrow
        />
      </S.Column>
    )
  }

  return (
    <S.Container onMouseLeave={() => handleDisableMenu()}>
      <S.Column data-testid='departments-column'>
        <DropdownColumn
          list={departments}
          handleOpenMenu={handleEnterDepartment}
          handleMouseLeave={() => clearTimeout(menuTimeout)}
          onClick={(item) => {
            pushToDataLayer({
              click_category: `${item.name}`
            })
          }}
          hrefPrefix={hrefPrefix}
        />
      </S.Column>
      {getSectionColumn()}
      {getCategoryColumn()}
      {renderBanner()}
    </S.Container>
  )
}
