import axios, { AxiosRequestConfig } from 'axios'
import { axiosException } from 'exception/axios'
import {
  CatalogProductModel,
  catalogProductModel
} from 'models/catalog/products'
import { CATALOG } from 'settings/endpoints'
import { endpoint } from 'settings/kernel'
import { CatalogProductResponse } from './products.types'

export async function getCatalogProduct(
  id: number,
  params: AxiosRequestConfig['params'] = {}
): Promise<CatalogProductModel> {
  try {
    const { data } = await axios.get<CatalogProductResponse>(
      `${endpoint}${CATALOG.PRODUCT}/${id}`,
      {
        params
      }
    )

    return catalogProductModel(data)
  } catch (error) {
    throw axiosException(
      error,
      `GET PRODUTO BUSCA - CATALOGO ID PRODUTO: ${id}`
    )
  }
}
