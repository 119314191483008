import { useMemo } from 'react'

import dynamic from 'next/dynamic'

import { IconArrow, IconHeaderCart, IconNotification } from '@k_frontend/icons'
import { Badge } from '@k_frontend/ui'

import { useHeaderContainer } from 'containers/HeaderContainer/HeaderContainer.hook'
import { useNotificationsStore } from 'containers/NotificationsContainer'
import { useRouter } from 'next/router'
import { useHeaderContext } from '../Header.context'
import * as S from '../Header.styles'
import Searchbar from '../Searchbar'
import { useHeaderMobile } from './HeaderMobile.hook'
import * as M from './HeaderMobile.styles'

const NavDrawer = dynamic(() => import('../NavDrawer'), {
  loading: () => (
    <div className='flex h-24 w-24 animate-pulse rounded-2 bg-black-500' />
  ),
  ssr: false
})

const SelectAddress = dynamic(() => import('../SelectAddress'), {
  ssr: false
})

const AccessibilityMenu = dynamic(
  () =>
    import('components/AccessibilityMenu').then(
      (module) => module.AccessibilityMenu
    ),
  {
    loading: () => (
      <div className='flex w-32 h-32 animate-pulse rounded-full bg-black-500' />
    ),
    ssr: false
  }
)

function Mobile() {
  const {
    isPrime,
    isMounted,
    clean,
    cartProductsQuantity,
    MobileLogo,
    addresses
  } = useHeaderContext()
  const { headerRef, handleClick, isLoggedIn } = useHeaderMobile()
  const { isViewFloatingMenu } = useHeaderContainer()

  const { noticeQuantity } = useNotificationsStore()
  const router = useRouter()

  const renderAccessibilityMenu = useMemo(() => {
    if (!isViewFloatingMenu) {
      return <AccessibilityMenu />
    }
  }, [isViewFloatingMenu])

  const renderHeaderSection = useMemo(() => {
    if (clean) {
      return (
        <M.CleanSection>
          <M.ButtonBack onClick={handleClick}>
            <IconArrow color='#fff' />
            Voltar
          </M.ButtonBack>
          <a id='logoKabum' href='/' aria-label='Página inicial KaBuM!'>
            {MobileLogo}
          </a>
        </M.CleanSection>
      )
    }

    return (
      <>
        <M.ContentSection>
          <M.LeftItemContent>
            <NavDrawer />
            {isLoggedIn && (
              <div
                className='ml-6'
                onClick={() => router.push('/minha-conta/notificacoes')}
                onKeyUp={() => router.push('/minha-conta/notificacoes')}
                data-testid='icon-notification'
              >
                <div className='relative'>
                  <IconNotification width={24} height={24} color='#fff' />
                  {noticeQuantity !== '0' && (
                    <span className='absolute px-4 bg-secondary-500 rounded-full -top-8 left-12 text-xs text-white-500'>
                      {noticeQuantity ?? '0'}
                    </span>
                  )}
                </div>
              </div>
            )}
          </M.LeftItemContent>
          <M.CenterItemContent
            as='a'
            id='logoKabum'
            href='/'
            aria-label='Página inicial KaBuM!'
          >
            {MobileLogo}
          </M.CenterItemContent>
          <M.RightItemContent>
            {renderAccessibilityMenu}
            <S.Link id='linkCarrinhoHeader' title='Carrinho' href='/carrinho'>
              <Badge content={cartProductsQuantity} position='topRight'>
                <IconHeaderCart size={24} color='#fff' />
              </Badge>
            </S.Link>
          </M.RightItemContent>
        </M.ContentSection>
      </>
    )
  }, [clean, isLoggedIn])

  return (
    <S.Container clean={clean}>
      <M.ContainerWrapper
        isPrime={isPrime}
        key={String(isMounted)}
        ref={headerRef}
        id='header'
      >
        {renderHeaderSection}
        <M.SearchbarSection id='barraBuscaKabum'>
          <Searchbar inputSearchId='input-busca' />
        </M.SearchbarSection>
        <SelectAddress />
      </M.ContainerWrapper>
    </S.Container>
  )
}

export default Mobile
