import styled, { css } from 'styled-components'

import { PropsStyled } from './SelectAddressMobile.types'

export const ContainerModal = styled.div<PropsStyled>`
  margin-top: ${({ theme }) => theme.spacing.xs};
  justify-content: space-between;
  height: ${({ theme }) => theme.rem(24)};
  max-width: 100%;
  background: ${({ theme }) => theme.colors.primary(500)};
  display: flex;
  align-items: center;
  ${({ theme }) => theme.font(12, 16, 400)};

  svg {
    align-items: center;
    fill: ${({ theme }) => theme.colors.white(500)};
    width: ${({ theme }) => theme.rem(14)};
    height: ${({ theme }) => theme.rem(14)};
  }

  .IconAdresses {
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }

  ${(props) => {
    if (props.isPrime) {
      return css`
        background: ${({ theme }) => theme.colors.black(900)};
      `
    }
  }}
`

export const Label = styled.div<PropsStyled>`
  flex-basis: auto;
  ${({ theme }) => theme.font(12, 16, 600)};
  color: ${({ theme }) => theme.colors.white(500)};
  margin-right: ${({ theme }) => theme.spacing.xxxs};

  ${(props) => {
    if (props.isPrime) {
      return css`
        color: ${({ theme }) => theme.colors.black(550)};
      `
    }
  }}
`

export const AddressSelect = styled.div<PropsStyled>`
  width: calc(100% - 105px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ theme }) => theme.font(12, 16, 400)};
  color: ${({ theme }) => theme.colors.white(500)};

  ${(props) => {
    if (props.isPrime) {
      return css`
        color: ${({ theme }) => theme.colors.black(550)};
      `
    }
  }}
`

export const WrapperAddress = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 12px);
`
