/* eslint-disable array-callback-return */
import CookiesInterface from 'interfaces/cookies'
import { handleStore } from 'utils/handleStore'

export const sellersArrayToString = (sellersArray) => {
  let id = '0000000000'
  let sellerId = '0'
  let originId = '0'
  let shippingOption = '0'
  let shippingDate = ''
  let shippingPeriod = ''
  let shippingName = ''
  let productCode = '0'
  let offerId = '0'
  let tagCode = '0'
  let quantity = '0'

  const returnString = []

  sellersArray.map((seller) => {
    sellerId = seller.id || '0'

    seller.origins.map((origin) => {
      originId = origin.id || '0'
      shippingOption = origin.shipping_code || '0'
      shippingDate = origin.shipping_date || ''
      shippingPeriod = origin.shipping_period || ''
      shippingName = origin.shipping_name || ''
      origin.products.map((product) => {
        id = product.id || '0'
        productCode = product.code || '0'
        offerId = product.offerId || product.offer_id || ''
        tagCode = product.tagCode || product.tag_code || ''
        quantity = product.quantity || '0'

        returnString.push(
          `${sellerId}|${originId}|${shippingOption}|${shippingDate}|${shippingPeriod}|${shippingName}|${id}|${productCode}|${quantity}|${offerId}|${tagCode}`
        )
      })
    })
  })

  return returnString.join('#')
}

export const addProductToCookies = (product) => {
  product.id_vendedor = product.id_vendedor || 0
  product.id_origem = product.id_origem || 1

  const CookieInstance = new CookiesInterface()
  const sellers = sellersStringToObj(CookieInstance.getSellers())
  const exists =
    sellers[product.id_vendedor] &&
    sellers[product.id_vendedor][product.id_origem] &&
    sellers[product.id_vendedor][product.id_origem].products.find((it) => {
      if (product.mercadoria_codigo || it.tagCode) {
        return (
          parseInt(it.code) === parseInt(product.codigo) &&
          parseInt(product.mercadoria_codigo) === parseInt(it.tagCode)
        )
      }

      return parseInt(it.code) === parseInt(product.codigo)
    })
  if (exists) return

  if (!sellers[product.id_vendedor]) {
    sellers[product.id_vendedor] = {}
    sellers[product.id_vendedor][product.id_origem] = {
      id: product.id_origem,
      shippingDate: '',
      shippingOptionCode: '',
      shippingPeriod: '',
      shippingName: ''
    }
    sellers[product.id_vendedor][product.id_origem].products = []
  } else if (!sellers[product.id_vendedor][product.id_origem]) {
    sellers[product.id_vendedor][product.id_origem] = {
      id: product.id_origem,
      shippingDate: '',
      shippingOptionCode: '',
      shippingPeriod: '',
      shippingName: ''
    }
    sellers[product.id_vendedor][product.id_origem].products = []
  }

  sellers[product.id_vendedor][product.id_origem].products.push({
    id: product.id,
    code: product.codigo,
    quantity: product.quantidade,
    offerId: product.id_marketplace,
    tagCode: product.mercadoria_codigo
  })

  CookieInstance._setCookie(
    'sellers',
    sellersArrayToString(sellersObjToArray(sellers))
  )
}

export const getServicesForCartRequestData = () => {
  const CookieInstance = new CookiesInterface()
  const services = CookieInstance.getServices()

  if (!services) {
    return []
  }

  return services.split('#').map((service) => {
    const [sellerId, originId, productCode, name, code] = service.split('|')

    return {
      sellerId: parseInt(sellerId),
      originId: parseInt(originId),
      productCode: parseInt(productCode),
      name,
      code: parseInt(code)
    }
  })
}

const sellersObjToArray = (sellers) => {
  return Object.keys(sellers).map((sellerId) => ({
    id: sellerId,
    origins: Object.values(sellers[sellerId])
  }))
}

const sellersStringToObj = (sellersString) => {
  const services = getServicesForCartRequestData()

  if (!sellersString) {
    return {}
  }

  const tmp = {}
  const stringList = sellersString.split('#')

  if (stringList.length === 0) {
    return {}
  }

  stringList.map((sellerString) => {
    const [
      sellerId,
      originId,
      shipping_code,
      shipping_date,
      shipping_period,
      shipping_name,
      id,
      productCode,
      quantity,
      offer_id,
      tag_code
    ] = sellerString.split('|')

    if (!tmp[sellerId]) {
      tmp[sellerId] = {}
    }

    if (!tmp[sellerId][originId]) {
      tmp[sellerId][originId] = {
        id: originId,
        shipping_code,
        shipping_date,
        shipping_period,
        shipping_name,
        products: []
      }
    }

    const serviceForProduct = services.filter(
      (service) =>
        service.sellerId === parseInt(sellerId) &&
        service.originId === parseInt(originId) &&
        service.productCode === parseInt(productCode)
    )

    tmp[sellerId][originId].products.push({
      id,
      code: productCode,
      quantity,
      tag_code,
      offer_id,
      services: serviceForProduct
        ? serviceForProduct.map((service) => service.code)
        : []
    })
  })

  return tmp
}
