import React from 'react'

import {
  TypeAddress,
  TypeAddressState,
  TypeDepartmentsMenuState,
  TypeKernel,
  TypeUserState,
  TypeVisitorState,
  V5BannerModel,
  WishlistApi
} from '@k_frontend/core'
import { StoreApi, UseBoundStore } from 'zustand'

export type HeaderControllerProps = {
  clean: boolean
  kernel: TypeKernel
  useUser: UseBoundStore<StoreApi<TypeUserState>>
  useAddresses: UseBoundStore<StoreApi<TypeAddressState>>
  useVisitor: UseBoundStore<StoreApi<TypeVisitorState>>
  useDepartmentsMenu: UseBoundStore<StoreApi<TypeDepartmentsMenuState>>
  cookieIsMobile: string
  DesktopLogo: React.ReactNode
  MobileLogo: React.ReactNode
  searchVariant: string
  hasTopBanner?: boolean
  logout: () => void
  getBanner: (
    kernel: TypeKernel,
    slug: string,
    param?: string
  ) => Promise<V5BannerModel>
  dataLayerBannerClick?: () => void
  isPrime: boolean
}

type ComponentMenu = {
  text?: string
  url?: string
  idPrefix?: any
  label?: string
  icon?: string
  link?: string
}

export type HeaderContextProviderProps = {
  children?: React.ReactNode
  clean: boolean
  kernel: TypeKernel
  useUser: UseBoundStore<StoreApi<TypeUserState>>
  useAddresses: UseBoundStore<StoreApi<TypeAddressState>>
  useVisitor: UseBoundStore<StoreApi<TypeVisitorState>>
  useDepartmentsMenu: UseBoundStore<StoreApi<TypeDepartmentsMenuState>>
  cookieIsMobile: string
  DesktopLogo: React.ReactNode
  MobileLogo: React.ReactNode
  searchVariant: string
  hasTopBanner?: boolean
  logout: () => void
  getBanner: (
    kernel: TypeKernel,
    slug: string,
    param?: string
  ) => Promise<V5BannerModel>
  dataLayerBannerClick?: () => void
  isPrime: boolean
}

type navDrawer = {
  a11y?: ComponentMenu
  myAccountLink?: ComponentMenu[]
  generalLinks?: ComponentMenu[]
  campaignLinks?: ComponentMenu[]
  institutionalLinks?: ComponentMenu[]
}

type HeaderJson = {
  headerMenu?: ComponentMenu[]
  navDrawer?: navDrawer
}

export type PropsContext = {
  clientName: string
  clientId: string
  profileImage: string
  isPrime: boolean
  isMounted: boolean
  clean: boolean
  handleLogout: () => void
  isLoggedIn: boolean
  addresses: TypeAddress[]
  wishlist: WishlistApi
  cartProductsQuantity: number
  setActiveExpandedMenu: (activeExpandedMenu: boolean) => void
  activeExpandedMenu: boolean
  kernel: TypeKernel
  flagBlackFriday: boolean
  currentAddress: TypeAddress
  setCurrentAddress: (addressCode: number) => void
  cookieIsMobile: string
  closeDepartments: TypeDepartmentsMenuState['closeDepartments']
  closeNavDrawer: TypeDepartmentsMenuState['closeNavDrawer']
  isDepartmentsActive: TypeDepartmentsMenuState['isDepartmentsActive']
  isNavDrawerActive: TypeDepartmentsMenuState['isNavDrawerActive']
  openDepartments: TypeDepartmentsMenuState['openDepartments']
  openNavDrawer: TypeDepartmentsMenuState['openNavDrawer']
  headerJson: HeaderJson
  DesktopLogo: React.ReactNode
  MobileLogo: React.ReactNode
  searchVariant: string
  hasTopBanner: boolean
  getBanner: (
    kernel: TypeKernel,
    slug: string,
    param?: string
  ) => Promise<V5BannerModel>
  dataLayerBannerClick?: () => void
}

export type HeaderStyleProps = {
  clean?: boolean
  isPrime?: boolean
  showAddresses?: boolean
}
