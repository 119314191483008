const keyValueToString = ([key, value], index) => {
  if (value === '' || value === null || value === undefined) return ''

  if (typeof value === 'object' && !Array.isArray(value)) {
    throw new Error('Please check your params')
  }

  if (index === 0) {
    return `?${key}=${value}`
  }

  return `${key}=${value}`
}

export function queryString(obj) {
  const removingEmptyKeys = Object.entries(obj)
    .map(keyValueToString)
    .filter(function (i) {
      return i
    })

  return removingEmptyKeys.join('&')
}

export function parse(string) {
  return Object.fromEntries(
    string.split('&').map((item) => {
      let [key, values] = item.split('=')

      if (values.indexOf(',') > -1) {
        values = values.split(',')
      }

      return [key, values]
    })
  )
}
