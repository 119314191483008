import { HttpAxiosClient } from 'adapters/httpAxiosClient/httpAxiosClient'
import {
  ExpectedError,
  LifeProofError,
  NonExistentRegistration,
  RecoveryAccountError,
  RegistrationInconsistenciesError
} from 'errors'
import { HttpRequestError } from 'errors/HttpRequestError'
import { LG } from 'settings/endpoints'
import {
  DoLoginProps,
  DoLoginResponse,
  DoLoginWithSocialAccountProps,
  DoLogoutProps
} from './login.types'

export class Login {
  constructor(public readonly httpClient = new HttpAxiosClient()) {}

  async doLogin({
    password,
    login,
    captcha,
    tmxSession,
    storeCode
  }: DoLoginProps): Promise<DoLoginResponse> {
    try {
      const { body } = await this.httpClient.request({
        method: 'post',
        url: LG.LOGIN,
        body: {
          customer_password: password,
          store_code: storeCode,
          customer_session_tmx: tmxSession,
          captcha_response: captcha,
          customer_login: login
        }
      })

      return {
        customerId: body.customer_id,
        customerSession: body.customer_session,
        maxAgeCookie: body.customer_session_ttl,
        isPrimeCustomer: body.CP_1286400,
        firstName: body.customer_first_name,
        profileImage: body.customer_profile_image_url,
        needToUpdateData: body.customer_needs_to_update_data,
        updateConsentForm: body.update_consent_form,
        customerEmail: body.customer_email || ''
      }
    } catch (error: unknown) {
      if (error instanceof HttpRequestError) {
        if (error.body?.error_code === RecoveryAccountError.errorCode) {
          throw new RecoveryAccountError(
            error.body.message,
            error.body.customer_cellphone,
            error.body.customer_email,
            error.body.recovery_hash
          )
        }

        if (
          error.body?.error_code === '002' ||
          error.body?.error_code === '011' ||
          error.body?.error_code === '012' ||
          error.body?.error_code === '013'
        ) {
          throw new LifeProofError(
            error.body.message,
            error.body.validation_link,
            error.body.error_code
          )
        }

        if (error.body?.error_code === NonExistentRegistration.errorCode) {
          throw new NonExistentRegistration(
            error.body.message,
            error.statusCode
          )
        }

        if (
          error.body?.error_code === RegistrationInconsistenciesError.errorCode
        ) {
          throw new RegistrationInconsistenciesError(error.body.message)
        }

        throw new ExpectedError(error.body.message, error.statusCode)
      }
    }
  }

  async doLoginWithSocialAccount({
    accessToken,
    captcha,
    socialAccountNetwork,
    storeCode,
    tmxSession
  }: DoLoginWithSocialAccountProps): Promise<DoLoginResponse> {
    try {
      const { body } = await this.httpClient.request({
        method: 'post',
        url: LG.OAUTH,
        body: {
          store_code: storeCode,
          access_token: accessToken,
          captcha_response: captcha,
          customer_session_tmx: tmxSession,
          social_network: socialAccountNetwork
        }
      })

      return {
        customerId: body.customer_id,
        customerSession: body.customer_session,
        maxAgeCookie: body.customer_session_ttl,
        isPrimeCustomer: body.CP_1286400,
        firstName: body.customer_first_name,
        profileImage: body.customer_profile_image_url,
        needToUpdateData: body.customer_needs_to_update_data,
        updateConsentForm: body.update_consent_form,
        customerEmail: body.customer_email || ''
      }
    } catch (error: unknown) {
      if (error instanceof HttpRequestError) {
        if (error.body?.error_code === RecoveryAccountError.errorCode) {
          throw new RecoveryAccountError(
            error.body.message,
            error.body.customer_cellphone,
            error.body.customer_email,
            error.body.recovery_hash
          )
        }

        if (
          error.body?.error_code === '002' ||
          error.body?.error_code === '011' ||
          error.body?.error_code === '012' ||
          error.body?.error_code === '013'
        ) {
          throw new LifeProofError(
            error.body.message,
            error.body.validation_link,
            error.body.error_code
          )
        }

        if (error.body?.error_code === NonExistentRegistration.errorCode) {
          throw new NonExistentRegistration(
            error.body.message,
            error.statusCode
          )
        }

        throw new ExpectedError(error.body.message, error.statusCode)
      }
    }
  }

  async doLogout({ customerId, customerSession }: DoLogoutProps) {
    try {
      const { body } = await this.httpClient.request({
        method: 'post',
        url: LG.SESSION_LOGOUT,
        body: {
          customer_id: customerId,
          customer_session: customerSession
        }
      })

      return {
        message: body.message
      }
    } catch (error: unknown) {
      if (error instanceof HttpRequestError) {
        throw new ExpectedError(error.body.message, error.statusCode)
      }
    }
  }
}
