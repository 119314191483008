const self = {}

self.SERVICE_NAME = 'prd-ecommerce-frontend-desktop'

self.ENVIRONMENT = 'prd'

self.ENDPOINT_PRI = 'https://services.prod.api.aws.grupokabum.com.br/'
self.ENDPOINT_PUB = 'https://servicespub.prod.api.aws.grupokabum.com.br/'

let endpoint = self.ENDPOINT_PUB

if (process.env.HOME) {
  endpoint = self.ENDPOINT_PRI
}

self.endpoint = endpoint

self.DESKTOP_STORE = '001'
self.MOBILE_STORE = '005'

self.MUST_SHOW_CANONICAL = 1

self.GTM_ID = 'GTM-W3L5TR8'

self.VWO_ACCOUNT_ID = '833870'

self.VWO_SDK_ENVIRONMENT = '8ec79847e3a07fb4139ada9680135458'

self.SEARCH_VARIANT = 'catalog'

self.KABUM_URL = 'https://www.kabum.com.br'

self.M_KABUM_URL = 'https://www.m.kabum.com.br'

self.KABUM_COMPLETE_URL = 'https://www.kabum.com.br'

self.KABUM_CGI_URL = 'https://www.kabum.com.br/cgi-local'

self.KABUM_COOKIE_URL = '.kabum.com.br'

self.STATIC_KABUM = 'https://static.kabum.com.br'

self.STATIC_KABUM_IMAGES_K5 = `${self.STATIC_KABUM}/conteudo/temas/001/imagens/k5/images`

self.STATIC_KABUM_IMAGES_SUPER_MAQUINA = `${self.STATIC_KABUM}/conteudo/temas/001/imagens/k5/images/hotsite/super_maquina`

self.STATIC_KABUM_FAQ_K5 = `${self.STATIC_KABUM}/conteudo/faq/data.json`

self.STATIC_FOOTER_JSON = `${self.STATIC_KABUM}/conteudo/footer/footer.json`

self.STATIC_HEADER_JSON = `${self.STATIC_KABUM}/conteudo/header/header.json`

self.FONT_POPPINS = `${self.STATIC_KABUM}/conteudo/temas/001/font/Poppins-Regular.woff`

self.DOMAIN_STATIC = `static.kabum.com.br`

self.DOMAIN_THEME = `themes.kabum.com.br`

self.DOMAIN_PRODUCTS = [
  's3.amazonaws.com',
  'gk-prod-images-001.s3.amazonaws.com',
  'gk-prod-images-001-original.s3.amazonaws.com',
  'gk-prod-openbox.s3.amazonaws.com',
  'images0.qa.kabumdev.com.br',
  'images1.qa.kabumdev.com.br',
  'images2.qa.kabumdev.com.br',
  'images3.qa.kabumdev.com.br',
  'images4.qa.kabumdev.com.br',
  'images5.qa.kabumdev.com.br',
  'images6.qa.kabumdev.com.br',
  'images7.qa.kabumdev.com.br',
  'images8.qa.kabumdev.com.br',
  'images9.qa.kabumdev.com.br',
  'images10.qa.kabumdev.com.br',
  'images.kabum.com.br',
  'images0.kabum.com.br',
  'images1.kabum.com.br',
  'images2.kabum.com.br',
  'images3.kabum.com.br',
  'images4.kabum.com.br',
  'images5.kabum.com.br',
  'images6.kabum.com.br',
  'images7.kabum.com.br',
  'images8.kabum.com.br',
  'images9.kabum.com.br',
  'images10.kabum.com.br'
]

self.URL_PREFIX_PROTOCOL_IMAGE = 'https://images6.kabum.com.br/'

// SERVER

self.SERVER_ENVIRONMENT = 'PRODUCTION'

// SITE EBIT

self.EBIT_KABUM = 'https://www.ebit.com.br/KaBuM'

// HOTSITE SEJA PRIME

self.HOTSITE_PRIME = 'https://www.kabum.com.br/hotsite/prime/'

self.HOTSITE_CARTAO_KABUM = 'https://www.kabum.com.br/hotsite/cartao/'

self.HOTSITE_APP_KABUM = 'https://www.kabum.com.br/hotsite/app/'

// ENDPOINTS BLACKFRIDAY

self.BACKEND_BLACKFRIDAY = '/blackflag'

// BACKEND
self.BACKEND_PAGAMENTO = '/order/v6/pagamento'

self.BACKEND_CONFIRMACAO = '/order/v6/confirmacao'

self.BACKEND_CONCLUIR = '/order/v6/concluir'

self.BACKEND_CHANGE_PAYMENT = `${endpoint}order/v6/change-payment`

self.BACKEND_CHANGE_CONFIRMATION = `${endpoint}order/v6/change-confirmation`

// ENDPOINTS MS CATEGORIA

self.BACKEND_CATEGORIAS = `/categoria/v1/categoria`

// ENDPOINTS MS LISTAGEM

self.BACKEND_LISTAGEM_OFERTA__URL = `https://b2lq2jmc06.execute-api.us-east-1.amazonaws.com/PROD/ofertas?`
self.BACKEND_BANNER_OFERTA__URL = `${endpoint}banner/v2/banner`

// ENDPOINTS MS CLIENTE

self.BACKEND_CLIENTE = '/cliente/v2/cliente'

self.BACKEND_CLIENTE_ALTERAR_DADOS = '/cliente/v3/cliente'

// ENDPOINTS MS FRETE
self.BACKEND_SHIPPING_OPTIONS = '/shipping/v2/quotation'
self.BACKEND_SHIPPING_ZIPCODE = '/shipping/v1/zip-query'

// ENDPOINTS TMS
self.BACKEND_TMS_SHIPPING_OPTIONS = '/tms-ux/v1/quote'

// ENDPOINTS MS HOME

self.BACKEND_OFERTA_CAMPANHA = '/home/v1/campanhaofertas/produto'

self.BACKEND_JUST_ARRIVED_PRODUCTS = '/home/v1/acabaramchegar/produto'

self.BACKEND_MOST_WANTED_PRODUCTS = '/home/v1/maisprocurados/produto'

self.BACKEND_CADASTRO_NEWSLETTER = `${endpoint}home/v1/newsletter`

// ENDPOINTS MS LOGIN

self.BACKEND_SESSION = '/login/v3/usuario/session'

self.BACKEND_USUARIO_VERIFICAR = `${endpoint}login/v3/usuario/verificar`

self.BACKEND_RECUPERAR_LOGIN = '/login/v3/recuperar-login'

self.BACKEND_RECUPERAR_SENHA = '/login/v4/recuperar-senha'

self.BACKEND_NOVA_SENHA = 'login/v3/nova-senha'

self.BACKEND_LOGOUT = `${endpoint}login/v3/usuario/logout`

self.BACKEND_USER_AUTH = `${endpoint}login/v3/usuario/auth`

self.BACKEND_LOGIN_MFA = `${endpoint}login/v4/usuario/enviar-codigo-ativacao`

self.BACKEND_ATIVAR_CONTA = `${endpoint}login/v4/usuario/ativar-usuario`

// ENDPOINTS MS BANNER

self.BACKEND_BANNERS = '/banner/v3/bannerhome'

self.BACKEND_BANNERS_MINHA_CONTA = `/banner/v3/banner-minhaconta`

self.BACKEND_BANNER = '/banner/v3/banner'

// ENDPOINT MS MINHA CONTA
self.BACKEND_PROTOCOLOS = `${endpoint}rma/v1/protocolos`

// ENDPOINT MS RMA

self.BACKEND_TROCA_E_ARREPENDIMENTO = `${endpoint}rma/v1/trocas-arrependimentos`

self.BACKEND_UPLOAD_IMAGEM_RMA = `${endpoint}upload/v1/upload/rma`

self.BACKEND_CAMINHO_IMAGEM_RMA = 'https://s3.amazonaws.com/rma.kabum.com.br/'

// ENDPOINTS MS DESCRICAO

self.BACKEND_DESCRICAO_PRODUTO = '/descricao/v2/descricao/produto'

self.BACKEND_DESCRICAO_OPENBOX = '/descricao/v1/openbox'

self.BACKEND_AVISEME_URL = `${endpoint}aviseme/`

self.BACKEND_FORUM_URL = `${endpoint}forum/`

self.BACKEND_FAMILIA_PRODUTO = `${endpoint}descricao/v1/descricao/familia`
// MS OPINIÕES

self.BACKEND_AVALIACOES_PRODUTO = `${endpoint}opinioes/v2/opinioes`

// ENDPOINTS MS ORDER

self.BACKEND_ORDER = `${self.ENDPOINT_PUB}order`

self.BACKEND_ADICIONAR_CARRINHO = '/order/v6/adicionar'

self.BACKEND_ORDER_CARRINHO = '/order/v6/carrinho'

self.BACKEND_WARRANTY_SERVICES = `${endpoint}warranty/v1/products`

self.BACKEND_ORDER_NUPAY = '/order/v6/nupay'

// ENDPOINTS DATALAYER

self.BACKEND_DL_VISITOR = `${self.ENDPOINT_PUB}customer/v1/dl-visitor`

// ENDPOINTS CAPTCHA

self.BACKEND_VALIDATE_CAPTCHA =
  'https://www.google.com/recaptcha/api/siteverify'

// ENDPOINTS CATALOG
self.BACKEND_CATALOG_PRODUCTS_BY_CATEGORY_URL = `${endpoint}catalog/v2/products-by-category`

self.BACKEND_CATALOG_PROMOTION = `${endpoint}catalog/v2/promotion`

self.BACKEND_CATALOG_RELEASES = `${endpoint}catalog/v2/releases`

self.BACKEND_CATALOG_HIGHLIGHTS = `${endpoint}catalog/v2/highlights`

self.BACKEND_CATALOG_ORIGINS = `${endpoint}catalog/v2/origins`

self.BACKEND_CATALOG_CROSS_CART = `${endpoint}catalog/v2/cross_carts`

self.BACKEND_CATALOG_CROSS_SELLING = `${endpoint}catalog/v2/cross_sellings`

self.BACKEND_CATALOG_SHOWCASE = `${endpoint}catalog/v2/showcase`

self.BACKEND_CATALOG_SEARCH = `${endpoint}catalog/v2/search`

self.BACKEND_CATALOG_SEO_SEARCHES = `${endpoint}catalog/v2/seo_searches`

// JSON

self.BACKEND_OFERTAS = 'http://proxy.elb.prd.kabum.com.br/ofertas_home.json'

// CLIENT CODES

self.CODIGO_COMPRE_CONFIE = '103388'

// KEYS

self.CAPTCHA_LOGIN_SITE = '6LfMTXIUAAAAAE-0W8PmIwyDIR9EJ61zvEHXI9Q3'

self.CAPTCHA_LOGIN_SECRET = '6LfMTXIUAAAAAFx63m1ANP0nXHm50VeOQ6e2ZE0E'

// ENDPOINT SOCIAL NETWORK LOGIN
self.LOGIN_FACEBOOK = `${endpoint}login/v4/oauth/facebook`
self.LOGIN_GOOGLE = `${endpoint}login/v4/oauth/google`

// ENDPOINT MINHA CONTA
self.BACKEND_RASTREIO = `${endpoint}shipping/v2/tracking`

// INSTITUCIONAL
self.BACKEND_PRIVACY =
  'https://static.kabum.com.br/data/institutional/privacy.json'
self.STATIC_KABUM_PRIVACY_K5 = `${self.STATIC_KABUM}/conteudo/privacidade/data.json`
self.BACKEND_TIME_STAMP = '/time.stamp'
self.BACKEND_DATA_UPDATE = '/data_update.json'

// PAYMENT CODES
self.BANKSLIP_CODE = 1
self.PAYPAL_CODE = 67
self.PIX_CODE = 98

self.BILLET_URL = `${self.KABUM_CGI_URL}/site/caixa/pagamentos/boleto_bancario/boleto.cgi`

self.AWS_BUCKET_LINK = 'https://gkt-prod-upload.s3.amazonaws.com'

self.BACKEND_BANNERS_LISTING = `${endpoint}banner/v3/banner-listagem?menu=`

self.BACKEND_BANNERS_BUSCA = `${endpoint}banner/v3/banner-listagem?tag=`

self.AWIN_TRACKING_SALE_TEST = 0

self.CYBERSOURCE_ID = 'k8vif92e'

// STATUS PEDIDO
self.ORDER_CANCELLED = 34

// ONE TRUST
self.ONETRUST_SCRIPT_DATA_DOMAIN = 'f2620729-44b9-4680-b58d-990c0d86bc10'

self.PRODUCTION = true

// ENDPOINT CUSTOMER
self.BACKEND_CUSTOMER_ACCOUNT_ACTIVATION = 'customer/v2/account/activate'
self.BACKEND_CUSTOMER_GET_RECAPTCHA_KEY = 'customer/v1/recaptcha'
self.BACKEND_CUSTOMER_MFACODE_VALIDATION = 'customer/v1/validation/code'
self.BACKEND_CUSTOMER_ACCOUNT_REGISTER_PF = 'customer/v1/account/pf'
self.BACKEND_CUSTOMER_ACCOUNT_REGISTER_PJ = 'customer/v1/account/pj'
self.BACKEND_CUSTOMER_ACCOUNT_ORIGIN = 'customer/v1/origin'
self.BACKEND_CUSTOMER_ACCOUNT_VALIDATION_CPF = 'customer/v1/validation/cpf'
self.BACKEND_CUSTOMER_ACCOUNT_RECOVERY = 'customer/v1/account/recovery'
self.BACKEND_CUSTOMER_ACCOUNT_VALIDATION = 'customer/v1/validation/account'
self.BACKEND_CUSTOMER_ACCOUNT_LAST_ORDER = 'customer/v1/order/last'
self.BACKEND_CUSTOMER_ACCOUNT_MY_ORDERS = 'customer/v1/order/details'
self.BACKEND_CUSTOMER_ACCOUNT_PIX = 'customer/v1/order/pix'
self.BACKEND_CUSTOMER_REDEEM_GIFTCARD = 'customer/v1/giftcard/redeem'
self.BACKEND_CUSTOMER_ACCOUNT_LIST_ORDERS = 'customer/v1/order/list'

// ENDPOINT EVALUATION
self.BACKEND_EVALUATUION_PRODUCT = 'customer/v1/evaluation/product'

// ENDPOINT HEALTHCHECK
self.BACKEND_CUSTOMER_ENDPOINTS_HEALTHCHECK = 'customer/healthcheck/'

// ENDPOINT NOTIFICATIONS
self.BACKEND_NOTIFICATIONS_RECOVERY_PASSWORD =
  'notifications/v1/password/recovery'
self.BACKEND_NOTIFICATIONS_RECOVERY_PASSWORD_TRIES =
  'notifications/v1/password/recovery/tries'
self.BACKEND_NOTIFICATIONS_MFA = 'notifications/v1/message/mfa'
self.BACKEND_NOTIFICATIONS = 'notifications/v1/message/'
self.BACKEND_NOTIFICATIONS_ACTIVATION_TRIES =
  'notifications/v2/message/activation/tries'

// ADDRESS
self.BACKEND_CUSTOMER_ADDRESS = 'customer/v1/address'

// WISHLIST
self.BACKEND_CUSTOMER_WISHLIST = `customer/v1/wishlist`
self.BACKEND_CUSTOMER_SWEEPSTAKES = `customer/v1/sweepstakes`

// RMA
self.BACKEND_RMA_PROTOCOL = `ecommerce-rma/v1/protocols/`
self.BAKCEND_RMA_SAC_STATUS = `ecommerce-rma/v1/help_desk/status_sac`
self.BACKEND_CUSTOMER_HELP_DESK = `customer/v1/help-desk`
self.BACKEND_CUSTOMER_PROTOCOL = `customer/v1/protocol`
self.BACKEND_RMA_BANKS = `ecommerce-rma/v1/rma/banks`
self.BACKEND_RMA_POST_PROTOCOL = `ecommerce-rma/v1/rma/protocol`
self.BACKEND_RMA_HELPORDER_QUESTIONS = `ecommerce-rma/v1/helporder/questions`
self.BACKEND_RMA_POST_SAC_FILES = `ecommerce-rma/v1/sac/upload`
self.BACKEND_RMA_GET_PROTOCOL_DATA = `ecommerce-rma/v1/sac`
self.BACKEND_RMA_POST_SAC_RESPONSES = `ecommerce-rma/v1/sac/interaction`
self.BACKEND_RMA_HELPORDER_PROTOCOL = `ecommerce-rma/v1/helporder/protocol`

// RMA - WARRANTY AND DEVOLUTION
self.BACKEND_RMA_WARRANTY_AND_DEVOLUTION = `ecommerce-rma/v1/rma/order`
self.BACKEND_RMA_WARRANTY_AND_DEVOLUTION_LABELS = `ecommerce-rma/v1/rma/merchandise-labels`
self.BACKEND_RMA_WARRANTY = `ecommerce-rma/v1/rma`
self.BACKEND_RMA_PRODUCT_QUESTIONS = `ecommerce-rma/v1/rma/product`

// CUSTOMER - PASSWORD RECOVERY
self.BACKEND_CUSTOMER_PASSWORD_RECOVERY = `customer/v1/password_recovery`

// CUSTOMER - PASSWORD RECOVERY
self.BACKEND_CUSTOMER_PASSWORD_RECOVERY = `customer/v1/password_recovery`

// CUSTOMER - EVALUATION
self.BACKEND_CUSTOMER_EVALUATION = `customer/v1/review`
self.BACKEND_CUSTOMER_EVALUATION_IMAGES = `https://s3.amazonaws.com/prod-ecommerce-product-review`

// DEPLOYMENT ID CHAT GENESYS
self.DEPLOYMENT_ID_GENESYS = '9922acbd-3230-4c17-bf78-dc8db5ce131d'

// API KEY SOLUCX CSAT
self.API_KEY_SOLUCX_CSAT = 'a110da1518f600096f9578f052074b5f'

// CSAT
self.BACKEND_AUTOATENDIMENTO_SOLUX_CSAT = `${endpoint}autoatendimento/v1/atendimento/genesys`
// ENDPOINT CADASTRO CLIENTES MAGALU
self.BACKEND_MAGALU_CUSTOMER_ORDER_VALIDATION =
  'customer/v1/magalu/order/validation'
self.BACKEND_MAGALU_CUSTOMER_ACCOUNT_VALIDATION =
  'customer/v1/magalu/account/validation'
self.BACKEND_MAGALU_CUSTOMER_REGISTER_ACCOUNT = 'customer/v1/magalu/account'

// MARKETPLACE - MENSAGERIA
self.BACKEND_SELLER_MESSENGER = 'seller-messenger/v1/customer/threads'

// TMX ID
self.TMX_COMPANY_ID = '5cfbehmb'

// RECAPTCHA V3
self.CAPTCHA_V3_PUBLIC_KEY = '6Lf11nkpAAAAACvReBMwR0OsYpxpOm33FBGRQ0sb'

// ENDPOINT ORDER
self.BACKEND_ORDER_DETAILS = 'customer/v1/order/details'

// ENDPOINT CHARGE
self.BACKEND_ORDER_CHARGE = 'order/v1/charge'

// ENDPOINT BANNER LOGIN
self.BACKEND_BANNER_V5 = `${endpoint}banner/v5`

// ENDPOINT V2 ORDER
self.BACKEND_ORDER_LIST = 'customer/v2/order/list'
self.BACKEND_ORDER_FILTER = 'customer/v2/order/filters'
self.BACKEND_ORDER_DETAILS_V2 = 'customer/v2/order'

// ACCOUNT
self.BACKEND_CUSTOMER_ACCOUNT = 'customer/v1/account'

// ENDPOINT CUSTOMER V2
self.BACKEND_CUSTOMER_ACCOUNT_V2 = 'customer/v2/account'
self.BACKEND_CUSTOMER_ACCOUNT_PF_REGISTER = 'customer/v2/account/pf'
self.BACKEND_CUSTOMER_ACCOUNT_PJ_REGISTER = 'customer/v2/account/pj'
self.BACKEND_CUSTOMER_ACCOUNT_MAGALU_V2 = 'customer/v2/magalu/account'

// ENDPOINT NOTIFICATIONS V2
self.BACKEND_MESSAGE_MFA_TRIES = 'notifications/v2/message/mfa/tries'
self.BACKEND_MESSAGE_MFA_SEND = 'notifications/v2/message/mfa'
self.BACKEND_MESSAGE_MFA_ACTIVATION_SEND = 'notifications/v3/message/activation'
self.BACKEND_MESSAGE_MFA_RECOVERY_SEND = 'notifications/v2/password/recovery'

// ENDPOINT TRACKING V2
self.BACKEND_TRACKING_V2 = 'shipping/v2/tracking'

// ENDPOINT FISCAL V2
self.BACKEND_FISCAL_V2 = 'fiscal/v2/nfe'

self.WALLU_API_URL = 'https://wallu-api.magazineluiza.com.br/api'
self.WALLU_API_ENVIROMENT = 'production'

// WALLET
self.STATIC_CARD_BRANDS = `${self.STATIC_KABUM}/conteudo/temas/001/imagens/bandeira_cartao/`

// INSIDER
self.INSIDER_PARTNER_NAME = 'kabum'

self.INSIDER_PARTNER_ID = '10010958'

// GOOGLE CONTA CORRETA
self.GOOGLE_CLIENT_ID =
  '516643720990-68e6l34vo8h9116ca4e744rs29m2cqe6.apps.googleusercontent.com'

// APPLE ID PROD
self.APPLE_CLIENT_ID = 'login.br.com.kabum.prd'

module.exports = self
