import { QueryClient } from '@tanstack/react-query'

const ONE_MINUTE_CACHE_TIME = 1000 * 60 * 60

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      staleTime: ONE_MINUTE_CACHE_TIME
    }
  }
})
