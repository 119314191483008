import dynamic from 'next/dynamic'

import {
  IconDefaultProfileLogo,
  IconHeaderCart,
  IconHeaderFavorites,
  IconHeaderSupport,
  IconNotification
} from '@k_frontend/icons'
import { Badge, Col, Container, Row } from '@k_frontend/ui'
import { useHeaderContainer } from 'containers/HeaderContainer/HeaderContainer.hook'
import { useMemo } from 'react'

import {
  NotificationsContainer,
  useNotificationsStore
} from 'containers/NotificationsContainer'
import { useHeaderContext } from '../Header.context'
import * as S from '../Header.styles'
import Menu from '../Menu'
import NavDrawer from '../NavDrawer'
import Searchbar from '../Searchbar'
import SelectAddress from '../SelectAddress'
import * as D from './HeaderDesktop.styles'

const ProfileInfo = dynamic(() => import('../ProfileInfo'), {
  loading: () => (
    <div
      id='blocoInformacoesCliente'
      className='flex flex-col justify-center items-center grow leading-4 ml-[6px] max-w-[130px] gap-4'
    >
      <div className='h-12 w-[85%] animate-pulse rounded-2 bg-black-500' />
      <div className='h-12 w-[85%] animate-pulse rounded-2 bg-black-500' />
    </div>
  ),
  ssr: false
})

const ProfileAvatarDynamic = (isPrime = false) =>
  dynamic(() => import('../ProfileAvatar'), {
    loading: () => (
      <figure
        id='blocoAvatarUsuario'
        className={`flex justify-center items-center rounded-[50%] border-[3px] border-solid  h-[36px] w-[36px] ${isPrime ? 'border-black-800' : 'border-[#347bbe]'}`}
      >
        <IconDefaultProfileLogo />
      </figure>
    ),
    ssr: false
  })

const AccessibilityMenu = dynamic(
  () =>
    import('components/AccessibilityMenu').then(
      (module) => module.AccessibilityMenu
    ),
  {
    loading: () => (
      <div className='flex w-32 h-32 animate-pulse rounded-full bg-black-500' />
    ),
    ssr: false
  }
)

const HandtalkToggle = dynamic(
  () =>
    import('components/HandtalkToggle').then((module) => module.HandtalkToggle),
  {
    loading: () => (
      <div className='flex w-24 h-24 animate-pulse rounded-full bg-black-500' />
    ),
    ssr: false
  }
)

const HeaderDesktop = () => {
  const {
    isPrime,
    clean,
    isLoggedIn,
    addresses,
    wishlist,
    cartProductsQuantity,
    isMounted,
    DesktopLogo
  } = useHeaderContext()
  const { isViewFloatingMenu } = useHeaderContainer()
  const { setIsOpenNotifications, isOpenNotifications, noticeQuantity } =
    useNotificationsStore()
  const ProfileAvatar = ProfileAvatarDynamic(isPrime)

  const renderAccessibilityMenu = useMemo(() => {
    if (!isViewFloatingMenu) {
      return <AccessibilityMenu />
    }
  }, [isViewFloatingMenu])

  return (
    <D.Container clean={false}>
      {isOpenNotifications && <NotificationsContainer />}
      <D.ContainerWrapper
        isPrime={isPrime}
        key={String(isMounted)}
        showAddresses={isLoggedIn && addresses?.length > 0}
      >
        <Container>
          <Row>
            <Col lg={8} xl={8} align='center'>
              {!clean && <NavDrawer />}
              <S.LogoSection id='logoKabum'>
                <D.ContainerLogo href='/' aria-label='Página inicial KaBuM!'>
                  {DesktopLogo}
                </D.ContainerLogo>
              </S.LogoSection>

              {!clean && (
                <D.SearchbarSection
                  id='barraBuscaKabum'
                  showAddresses={isLoggedIn && addresses?.length > 0}
                >
                  <Searchbar inputSearchId='input-busca' />

                  {isLoggedIn && addresses?.length > 0 && <SelectAddress />}
                </D.SearchbarSection>
              )}
            </Col>
            <Col lg={4} xl={4} align='center' justify='space-between'>
              <D.UserInfoContainer>
                <ProfileAvatar />
                <ProfileInfo />
              </D.UserInfoContainer>
              {clean && (
                <D.HandtalkWrapper>
                  <HandtalkToggle />
                </D.HandtalkWrapper>
              )}

              {!clean && (
                <S.IconsContainer>
                  {renderAccessibilityMenu}
                  <S.Link id='linkSacHeader' title='SAC' href='/faq'>
                    <IconHeaderSupport size={24} />
                  </S.Link>
                  <S.Link
                    id='linkFavoritosHeader'
                    title='Favoritos'
                    href='/minha-conta/favoritos'
                  >
                    <Badge
                      content={!!wishlist && wishlist.total_wishes}
                      position='topRight'
                    >
                      <IconHeaderFavorites size={20} />
                    </Badge>
                  </S.Link>
                  {isLoggedIn && (
                    <div
                      className='flex items-center justify-center w-[36px] h-[36px]'
                      onClick={() =>
                        setIsOpenNotifications(!isOpenNotifications)
                      }
                      onKeyUp={() =>
                        setIsOpenNotifications(!isOpenNotifications)
                      }
                      data-testid='icon-notification'
                    >
                      <div className='relative cursor-pointer'>
                        <IconNotification
                          width={24}
                          height={24}
                          color='#ffffffcc'
                        />
                        {noticeQuantity !== '0' && (
                          <span className='flex items-center font-bold absolute h-20 px-6 bg-secondary-600 rounded-full -top-[10px] left-12 text-xs text-black-300'>
                            {noticeQuantity}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  <S.Link
                    id='linkCarrinhoHeader'
                    title='Carrinho'
                    href='/carrinho'
                  >
                    <Badge content={cartProductsQuantity} position='topRight'>
                      <IconHeaderCart size={20} />
                    </Badge>
                  </S.Link>
                </S.IconsContainer>
              )}
            </Col>
          </Row>
        </Container>
      </D.ContainerWrapper>

      {!clean && <Menu />}
    </D.Container>
  )
}

export default HeaderDesktop
