import { RefObject, useEffect, useState } from 'react'

type IntersectionObserverInit = {
  root?: Element | null
  rootMargin?: string
  threshold?: number | number[]
}

export function useVisibility(
  ref: RefObject<HTMLElement>,
  observerSettings: IntersectionObserverInit
): boolean {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
    }, observerSettings)

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref, observerSettings])

  return isIntersecting
}
