import {
  IconAlert,
  IconError,
  IconInformation,
  IconSuccess
} from '@k_frontend/icons'

export const TOAST_TYPES = {
  success: {
    icon: <IconSuccess />,
    iconClass: 'success-icon',
    defaultToastColor: 'success'
  },
  warning: {
    icon: <IconAlert />,
    iconClass: 'warning-icon',
    defaultToastColor: 'warning'
  },
  info: {
    icon: <IconInformation width='16' height='16' color='#0060b1' />,
    iconClass: 'info-icon',
    defaultToastColor: 'info'
  },
  error: {
    icon: <IconError />,
    iconClass: 'error-icon',
    defaultToastColor: 'error'
  }
}
