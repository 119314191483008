import { useToast } from 'providers/Toast/Toast.hook'
import { useState } from 'react'
import { useEffect, useRef } from 'react'

export function useToastCard({ toastId }: { toastId?: string | undefined }) {
  const { removeToast } = useToast()
  const timerID = useRef(null)
  const progressRef = useRef(null)
  const [dismissed, setDismissed] = useState(false)

  function handleDismiss() {
    setDismissed(true)
    setTimeout(() => {
      removeToast(toastId)
    }, 400)
  }

  function handleMouseEnter() {
    clearTimeout(timerID.current)
    progressRef.current.style.animationPlayState = 'paused'
  }

  function handleMouseLeave() {
    const remainingTime =
      (progressRef.current.offsetWidth /
        progressRef.current.parentElement.offsetWidth) *
      4000

    progressRef.current.style.animationPlayState = 'running'

    timerID.current = setTimeout(() => {
      handleDismiss()
    }, remainingTime)
  }

  useEffect(() => {
    timerID.current = setTimeout(() => {
      handleDismiss()
    }, 4000)

    return () => {
      clearTimeout(timerID.current)
    }
  }, [])

  return {
    handleDismiss,
    handleMouseEnter,
    handleMouseLeave,
    progressRef,
    dismissed
  }
}
