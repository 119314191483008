import {
  IconAttention,
  IconBasket,
  IconCalendar,
  IconCart,
  IconCelebration,
  IconCheckFill,
  IconCloseFill,
  IconCreditCardFill,
  IconCustomerService,
  IconDelivery,
  IconFavorite,
  IconNfe,
  IconThumbsUpFilled,
  IconTruck,
  IconUser,
  IconYoutube
} from '@k_frontend/icons'
import { ComponentType } from 'react'

export const SAC_PHONE = '(19) 2114-4444'
export const SAC_INTERNATIONAL_PHONE = `+55 ${SAC_PHONE}`
export const ONE_MINUTE_IN_SECONDS = 60

export const KEY_STORAGE_TMX = 'kbm_tmx'

export const MY_ACCOUNT_SHORTCUTS = [
  {
    id: 1,
    icon: IconBasket,
    label: 'Meus pedidos',
    slug: 'minha-conta/meus-pedidos',
    textInfo: 'Veja históricos e acompanhe suas compras.'
  },
  {
    id: 2,
    icon: IconUser,
    label: 'Meus dados',
    slug: 'minha-conta/meus-dados',
    textInfo:
      'Altere seus dados cadastrados, endereços ou cadastre um novo endereço.'
  },
  {
    id: 3,
    icon: IconCreditCardFill,
    label: 'Carteira',
    slug: '/minha-conta/carteira',
    textInfo: 'Gerencie seus cartões, créditos e resgate gift card.'
  },
  {
    id: 4,
    icon: IconCustomerService,
    label: 'Protocolos',
    slug: '/minha-conta/protocolos',
    textInfo: 'Aqui você encontra seus protocolos de atendimento.'
  },
  {
    id: 5,
    icon: IconThumbsUpFilled,
    label: 'Avaliações',
    slug: '/minha-conta/avaliacoes',
    textInfo: 'Avalie suas compras e visualize suas avaliações e comentários.'
  },
  {
    id: 6,
    icon: IconFavorite,
    label: 'Favoritos',
    slug: '/minha-conta/favoritos',
    textInfo: 'Consulte sua lista de produtos favoritados.'
  }
]

export const MILISECOND = 1000
export const MINUTE = 60
export const THIRTY_MINUTES_TO_MILISECOND = 30 * MINUTE * MILISECOND

export const NOTIFICATION_ICON: {
  [key: number]: { Icon: ComponentType<{ className?: string }> }
} = {
  1: { Icon: IconCart },
  2: { Icon: IconCheckFill },
  3: { Icon: IconDelivery },
  4: { Icon: IconNfe },
  5: { Icon: IconTruck },
  6: { Icon: IconCelebration },
  7: { Icon: IconDelivery },
  8: { Icon: IconAttention },
  9: { Icon: IconCheckFill },
  10: { Icon: IconCalendar },
  11: { Icon: IconCloseFill }
}

export const NOTIFICATION_BANNER: {
  [key: number]: { Icon: ComponentType<{ className?: string }> }
} = {
  1: null,
  2: { Icon: IconYoutube }
}

export const KIT_PC_BUNDLE = 7
export const PRODUCT_MARKETPLACE = 5
export const KABUM_SELLER_CODE = 1
export const MAGALU_SELLER_CODE = 1000
