import styled, { css } from 'styled-components'

import * as S from '../Searchbar.styles'
import { DropdownSearchProps } from '../Searchbar.types'

export const Container = styled(S.Container)`
  position: inherit;

  &:focus-within {
    ${(props) => {
      if (props.isSearchResult) {
        return css`
          &:after {
            top: 0;
            z-index: 1;
          }
        `
      }
    }}
  }
`

export const Form = styled(S.Form)`
  position: relative;
  flex: 1;
  width: calc(100vw - ${({ theme }) => theme.rem(172)});
  z-index: 99999;

  input {
    border-style: solid;
    border-width: ${({ theme }) => theme.rem(1)};
    border-color: ${({ theme }) => theme.colors.black(600)};
  }
`

export const Button = styled(S.Button)`
  width: ${({ theme }) => theme.rem(14)};
  height: ${({ theme }) => theme.rem(14)};
  top: 50%;
  right: ${({ theme }) => theme.spacing.xxs};
  transform: translateY(-50%);
  z-index: 99999;

  &:before {
    display: none;
  }
`
export const DropdownSearch = styled(S.DropdownSearch)<DropdownSearchProps>`
  top: ${(props) => (props.isViewFloatingMenu ? props.theme.rem(52) : props.theme.rem(96))};
  width: 100%;
  z-index: 99999;

  .IconSearch {
    position: relative;
    top: ${({ theme }) => theme.rem(2)};
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }
`

export const TitleItem = styled(S.TitleItem)`
  width: 100%;
`
