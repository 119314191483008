import { axios } from 'services/axios'
import { BACKEND_CUSTOMER_ENDPOINTS_HEALTHCHECK } from 'settings/kernel'

export async function hc() {
  try {
    const { data } = await axios.get(BACKEND_CUSTOMER_ENDPOINTS_HEALTHCHECK)

    return data
  } catch (error) {
    throw error.response.data
  }
}
