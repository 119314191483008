import axios from 'axios'
import { HttpRequestError } from 'errors/HttpRequestError'
import { endpoint } from 'settings/kernel'
import { Request, Response } from './httpAxiosClient.types'

export class HttpAxiosClient {
  constructor(private readonly baseUrl = endpoint) {}

  async request<T = any>(data: Request): Promise<Response<T>> {
    try {
      const response = await axios(data.url, {
        baseURL: this.baseUrl,
        method: data.method,
        headers: data.headers,
        params: data.params,
        data: data.body
      })

      return {
        body: response.data,
        statusCode: response.status
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new HttpRequestError(error.response.data, error.response.status)
      }

      throw error
    }
  }

  buildUrlWithParams(
    url: string,
    params?: Record<string, string | number | boolean>
  ): string {
    if (!params) {
      return url
    }

    const queryString = Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`
      )
      .join('&')

    return `${url}?${queryString}`
  }
}
