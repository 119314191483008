import { Fragment, useState } from 'react'

import { pushToDataLayer } from '@k_frontend/core'
import { IconCollapseHeader } from '@k_frontend/icons'
import { Container } from '@k_frontend/ui'
import ThemeDefault from 'theme'

import DepartmentsMenu from '../DepartmentsMenu'
import { useHeaderContext } from '../Header.context'
import * as S from './Menu.styles'

export default function Menu() {
  const [activeDepartmentsMenu, setActiveDepartmentsMenu] =
    useState<boolean>(false)
  const { kernel, headerJson, dataLayerBannerClick } = useHeaderContext()

  const onCloseMenu = () => {
    if (activeDepartmentsMenu) {
      setActiveDepartmentsMenu(false)
    }
  }

  return (
    <S.Wrapper onMouseLeave={() => onCloseMenu()}>
      <Container>
        <S.Nav>
          <S.DropdownButton
            active={activeDepartmentsMenu}
            onMouseEnter={() => setActiveDepartmentsMenu(true)}
          >
            <span>DEPARTAMENTOS</span>
            <IconCollapseHeader
              width={12}
              height={12}
              color={ThemeDefault.colors.white(500)}
            />
          </S.DropdownButton>
          {headerJson?.navDrawer &&
            headerJson?.headerMenu?.map((item, index) => (
              <Fragment key={index}>
                <S.Link
                  href={item.url}
                  id={`${item.idPrefix}MenuSuperior`}
                  onClick={() =>
                    pushToDataLayer({
                      click_content: item.text
                    })
                  }
                >
                  {item.text}
                </S.Link>
                <S.Stick />
              </Fragment>
            ))}
        </S.Nav>
      </Container>
      {activeDepartmentsMenu && (
        <Container>
          <S.Dropdown>
            <DepartmentsMenu
              kernel={kernel}
              onCloseMenu={onCloseMenu}
              dataLayerBannerClick={dataLayerBannerClick}
            />
            <S.DropdownBackground onClick={onCloseMenu} />
          </S.Dropdown>
        </Container>
      )}
    </S.Wrapper>
  )
}
