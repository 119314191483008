import { createContext, useContext, useEffect, useState } from 'react'

import {
  TypeAddressState,
  TypeDepartmentsMenuState,
  TypeUserState,
  TypeVisitorState,
  sanitizeCookies,
  useWishlist
} from '@k_frontend/core'
import axios from 'axios'
import Cookie from 'universal-cookie'
import shallow from 'zustand/shallow'

import { HeaderContextProviderProps, PropsContext } from './Header.types'

import { useAttendanceStore } from 'components/PageMyAccount/Attendance/Page/Attendance.store'
import {
  useNotificationsContainer,
  useNotificationsStore
} from 'containers/NotificationsContainer'

const Context = createContext<PropsContext>(null)

const useUserSelector = (state: TypeUserState) => ({
  clientId: state.clientId,
  clientName: state.clientName,
  profileImage: state.profileImage,
  session: state.session,
  reset: state.reset,
  isLoggedIn: state.isLoggedIn,
  init: state.init,
  flagBlackFriday: state.flagBlackFriday
})

const useAddressesSelector = (state: TypeAddressState) => ({
  reset: state.reset,
  addresses: state.addresses,
  currentAddress: state.currentAddress,
  setCurrentAddress: state.setCurrentAddress
})

const useVisitorSelector = (state: TypeVisitorState) => ({
  reset: state.reset,
  init: state.init
})

const useDepartmentsMenuSelector = (state: TypeDepartmentsMenuState) => ({
  closeDepartments: state.closeDepartments,
  closeNavDrawer: state.closeNavDrawer,
  isDepartmentsActive: state.isDepartmentsActive,
  isNavDrawerActive: state.isNavDrawerActive,
  openDepartments: state.openDepartments,
  openNavDrawer: state.openNavDrawer
})

const getCartProductsQuantity = () => {
  const ClassCookie = new Cookie()
  const sellersCookie = sanitizeCookies(ClassCookie.get('sellers'))

  if (sellersCookie) {
    const decodedString = decodeURI(sellersCookie)
    const productsArray = decodedString.split('#')

    return productsArray.length
  }

  return 0
}

export function HeaderContextProvider({
  children,
  clean,
  kernel,
  useUser,
  useAddresses,
  useVisitor,
  useDepartmentsMenu,
  cookieIsMobile,
  DesktopLogo,
  MobileLogo,
  searchVariant,
  hasTopBanner,
  logout,
  getBanner,
  dataLayerBannerClick,
  isPrime
}: HeaderContextProviderProps) {
  const {
    clientId,
    clientName,
    profileImage,
    session,
    reset: resetUser,
    isLoggedIn,
    flagBlackFriday
  } = useUser<Partial<TypeUserState>>(useUserSelector, shallow)
  const { reset: resetVisitor } = useVisitor<Partial<TypeVisitorState>>(
    useVisitorSelector,
    shallow
  )
  const {
    reset: resetAddresses,
    addresses,
    currentAddress,
    setCurrentAddress
  } = useAddresses<Partial<TypeAddressState>>(useAddressesSelector, shallow)

  const {
    closeDepartments,
    closeNavDrawer,
    isDepartmentsActive,
    isNavDrawerActive,
    openDepartments,
    openNavDrawer
  } = useDepartmentsMenu<Partial<TypeDepartmentsMenuState>>(
    useDepartmentsMenuSelector,
    shallow
  )

  const { resetAttendanceStore } = useAttendanceStore(
    (state) => ({
      resetAttendanceStore: state.resetAttendanceStore
    }),
    shallow
  )

  const cartProductsQuantityCookie = getCartProductsQuantity() || 0
  const [cartProductsQuantity, setCartProductQuantity] = useState(0)
  const [activeExpandedMenu, setActiveExpandedMenu] = useState<boolean>(false)
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [headerJson, setHeaderJson] = useState()
  const { wishlist, resetWishList } = useWishlist()
  const { allCustomerNotices } = useNotificationsContainer()
  const { setNoticeQuantity } = useNotificationsStore()

  async function getHeaderJson() {
    const cachedMenu = sessionStorage.getItem('menuJsonStatic')

    if (cachedMenu) {
      setHeaderJson(JSON.parse(cachedMenu))
    } else {
      const { data } = await axios.get(kernel.STATIC_HEADER_JSON)
      setHeaderJson(data)
      sessionStorage.setItem('menuJsonStatic', JSON.stringify(data))
    }
  }

  useEffect(() => {
    setIsMounted(true)
    getHeaderJson()
  }, [clientId, session, hasTopBanner])

  useEffect(() => {
    setCartProductQuantity(cartProductsQuantityCookie)
  }, [cartProductsQuantityCookie])

  useEffect(() => {
    if (clientId) {
      const hasNextPage = allCustomerNotices?.nextPage
      const noticesQuantity = allCustomerNotices?.notifications.length

      if (hasNextPage) {
        setNoticeQuantity('8+')
      } else {
        setNoticeQuantity(noticesQuantity?.toString())
      }
    }
  }, [clientId])

  const handleLogout = () => {
    logout()
    resetAddresses()
    resetVisitor()
    resetUser()
    resetWishList()
    resetAttendanceStore(0)
  }

  const propsValue: PropsContext = {
    clientName,
    clientId,
    profileImage,
    isPrime,
    isMounted,
    clean,
    handleLogout,
    isLoggedIn,
    addresses,
    wishlist,
    cartProductsQuantity,
    setActiveExpandedMenu,
    activeExpandedMenu,
    kernel,
    flagBlackFriday,
    currentAddress,
    setCurrentAddress,
    cookieIsMobile,
    closeDepartments,
    closeNavDrawer,
    isDepartmentsActive,
    isNavDrawerActive,
    openDepartments,
    openNavDrawer,
    headerJson,
    DesktopLogo,
    MobileLogo,
    searchVariant,
    hasTopBanner,
    getBanner,
    dataLayerBannerClick
  }
  return <Context.Provider value={propsValue}>{children}</Context.Provider>
}

export function useHeaderContext(): PropsContext {
  return useContext(Context)
}
