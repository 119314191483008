import { useEffect, useRef } from 'react'
import { useHeaderContext } from '../Header.context'

export function useHeaderMobile() {
  const {
    isPrime,
    isMounted,
    clean,
    cartProductsQuantity,
    isLoggedIn,
    addresses,
    MobileLogo,
    hasTopBanner
  } = useHeaderContext()
  const headerRef = useRef(null)
  const headerRect = headerRef.current?.getBoundingClientRect()
  const headerBottom =
    headerRect?.bottom + (!hasTopBanner && Math.abs(headerRect?.top))

  useEffect(() => {
    function calcScreen() {
      const vh = (window.innerHeight - headerBottom) * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      document.documentElement.style.setProperty(
        '--top-distance',
        `${headerBottom}px`
      )
    }
    let resizeTimeout: ReturnType<typeof setTimeout>
    function resizeThrottler() {
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        calcScreen()
      }, 300)
    }
    calcScreen()
    window.addEventListener('resize', resizeThrottler)
    return () => {
      window.removeEventListener('resize', resizeThrottler)
    }
  }, [isLoggedIn, headerBottom, hasTopBanner])

  function handleClick(): void {
    window.history.back()
  }

  return {
    isPrime,
    isMounted,
    clean,
    cartProductsQuantity,
    addresses,
    MobileLogo,
    headerRef,
    handleClick,
    isLoggedIn
  }
}
