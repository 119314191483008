import TagManager from 'react-gtm-module'

import Kernel from 'settings/kernel'

export function initGtm(): void {
  if (typeof document !== 'undefined') {
    if (Kernel.SERVER_ENVIRONMENT !== 'DEVELOPMENT') {
      TagManager.initialize({
        gtmId: Kernel.GTM_ID
      })
    }
  }
}
