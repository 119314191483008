import { tv } from 'tailwind-variants'

export const ToastWrapper = tv({
  base: ['animate-slideInAnimation', 'shadow-md', 'rounded-4'],
  variants: {
    desmissed: {
      true: 'animate-slideOutAnimation'
    },
    colors: {
      success: 'bg-success-100',
      warning: 'bg-secondary-100',
      error: 'bg-error-100',
      info: 'bg-primary-100'
    }
  }
})

export const ToastTimerBar = tv({
  base: ['h-full', 'animate-progressBarAnimation'],
  variants: {
    colors: {
      success: 'bg-success-500',
      warning: 'bg-secondary-500',
      error: 'bg-error-500',
      info: 'bg-primary-500'
    }
  }
})
