import styled from 'styled-components'

import { TypeFooterLinksPropsStyled } from './FooterLinks.types'

export const LinksContainer = styled.div<TypeFooterLinksPropsStyled>`
  background: ${({ isPrime, theme }) =>
    isPrime ? theme.colors.black(800) : theme.colors.primary(600)};
  padding: ${({ theme }) => theme.spacing.md} 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    padding: ${({ theme }) => theme.spacing.lg} 0;
  }
`
