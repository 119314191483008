import { TypeAddToCartEventProps, useUser } from '@k_frontend/core'
import { getCatalogProduct } from 'services/catalog/products'

import { ActionAddToCart } from './addToCartById.types'

export async function addToCartById(
  productId: number,
  recommendationTitle: string,
  dataLayerEventAddToCart: (data: TypeAddToCartEventProps) => void,
  dispatch: (arg: ActionAddToCart) => ActionAddToCart
) {
  const isPrime = useUser((state) => state.isPrime)

  const params = {
    is_prime: isPrime
  }

  const product = await getCatalogProduct(productId, params)
  const productToDataLayer = {
    brand: product?.manufacturer?.name,
    category: product.category,
    dimension20: Number(product.sellerId),
    dimension21: product.sellerName,
    dimension40: recommendationTitle,
    id: product.code.toString(),
    name: product.name,
    price: product?.offer?.price,
    quantity: 1
  }

  dataLayerEventAddToCart({ payload: [productToDataLayer] })
  dispatch({
    type: 'ADD_PRODUCT_TO_CART',
    products: { ...product, di: product.offer?.hashCode },
    addType: 'normal'
  })
}
