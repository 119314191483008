import { create } from 'zustand'

import { persist } from 'zustand/middleware'
import { AttendanceStore } from './Attendance.types'

const initialSatte = {
  isFormVisible: false,
  isUnansweredFormVisible: false,
  successInSubmittingForm: false,
  stateDataForm: {
    selectedSubject: '',
    selectedQuestion: '',
    selectedAnswer: '',
    questionAnswerIdSelected: 0
  },
  updatedAt: 0
}

export const useAttendanceStore = create<AttendanceStore>()(
  persist(
    (set) => ({
      ...initialSatte,
      resetAttendanceStore: (time = 5000) =>
        setTimeout(() => {
          set(initialSatte)
        }, time),
      setIsFormVisible(value: boolean) {
        set({ isFormVisible: value })
      },
      setIsUnansweredFormVisible(value: boolean) {
        set({ isUnansweredFormVisible: value })
      },
      setSuccessInSubmittingForm(value: boolean) {
        set({ successInSubmittingForm: value })
      },
      setSelectedSubject: (subject) =>
        set((state) => ({
          stateDataForm: {
            ...state.stateDataForm,
            selectedSubject: subject
          }
        })),

      setSelectedQuestion: (question) =>
        set((state) => ({
          stateDataForm: {
            ...state.stateDataForm,
            selectedQuestion: question
          }
        })),
      setSelectedAnswer: (answer) =>
        set((state) => ({
          stateDataForm: {
            ...state.stateDataForm,
            selectedAnswer: answer
          }
        })),
      setQuestionAnswerIdSelected: (answerID) =>
        set((state) => ({
          stateDataForm: {
            ...state.stateDataForm,
            questionAnswerIdSelected: answerID
          }
        })),
      setUpdatedAt(timeStamp) {
        set({ updatedAt: timeStamp })
      }
    }),
    {
      name: 'kbm-form-attendance'
    }
  )
)
