import { tv } from 'tailwind-variants'

export const container = tv({
  base: [
    'flex',
    'flex-col',
    'items-center',
    'justify-center',
    'gap-16',
    'bg-transparent',
    'px-16',
    'py-48',
    '[&_svg]:h-auto'
  ],
  variants: {
    fullWidth: {
      true: ['w-full'],
      false: ['w-fit']
    },
    iconWidth: {
      sm: ['[&_svg]:w-32'],
      md: ['[&_svg]:w-64'],
      lg: ['[&_svg]:w-[100px]']
    }
  }
})

export const fill = tv({
  base: ['fill-secondary-500']
})

export const description = tv({
  base: ['text-secondary-500', 'text-base', 'font-bold']
})
