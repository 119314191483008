import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useRouter } from 'next/router'

import {
  getBanner,
  useAddresses,
  useDepartmentsMenu,
  useUser,
  useVisitor
} from '@k_frontend/core'
import { SkipLink } from '@k_frontend/ui'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { getCookie } from 'cookies-next'
import { Logout } from 'services/logout'
import Kernel from 'settings/kernel'

import { LayoutProps as Props } from './types'

const Newsletter = dynamic(() => import('components/Newsletter'), {
  ssr: false
})

export default function LayoutController({
  children,
  cookieIsMobile,
  cookieIsPrime
}: Props) {
  const router = useRouter()
  const { pathname } = router
  const searchVariant = getCookie('searchVariation') || Kernel.SEARCH_VARIANT

  const cleanHeaderRoutes = ['/login', '/cadastro']
  const withoutNewsletterPages = [
    '/login',
    '/cadastro',
    '/minha-conta',
    '/minha-conta/meus-dados',
    '/minha-conta/meus-pedidos',
    '/minha-conta/avaliacoes',
    '/atendimento',
    '/minha-conta/protocolos',
    '/minha-conta/favoritos',
    '/minha-conta/sorteio',
    '/minha-conta/garantia-arrependimento',
    '/minha-conta/garantia-arrependimento/garantia',
    '/minha-conta/garantia-arrependimento/arrependimento',
    '/minha-conta/garantia-arrependimento/concluido'
  ]

  const cleanFooterRoutes = ['/login']

  const isClean = cleanFooterRoutes.includes(pathname)
  const hasNewsLetter = !withoutNewsletterPages.includes(pathname)

  const removeHeaderAndFooter = [
    '/hotsite',
    '/hotsite/cadastro',
    '/500',
    '/portaldeprivacidade',
    '/chat_app'
  ]

  const renderSkipLinks = (
    <>
      <SkipLink />
      <SkipLink id='footer' text='Ir para o rodapé' />
    </>
  )

  const renderNewsLetter = !isClean && hasNewsLetter && (
    <Newsletter isPrime={cookieIsPrime} />
  )

  if (removeHeaderAndFooter.includes(pathname)) {
    return <>{children}</>
  }

  return (
    <>
      {renderSkipLinks}
      <Header
        getBanner={getBanner}
        clean={cleanHeaderRoutes.includes(pathname)}
        kernel={Kernel}
        isPrime={cookieIsPrime}
        useUser={useUser}
        useVisitor={useVisitor}
        useAddresses={useAddresses}
        useDepartmentsMenu={useDepartmentsMenu}
        cookieIsMobile={cookieIsMobile}
        logout={Logout}
        DesktopLogo={
          cookieIsPrime ? (
            <Image
              style={{ height: `100%` }}
              width='158'
              height='64'
              src={`${Kernel.STATIC_KABUM}/conteudo/icons/logoPrime.svg`}
              alt='Logo Kabum prime'
            />
          ) : (
            <Image
              style={{ height: `100%` }}
              width='158'
              height='64'
              src={`${Kernel.STATIC_KABUM}/conteudo/icons/logo.svg`}
              alt='Logo Kabum'
            />
          )
        }
        MobileLogo={
          cookieIsPrime ? (
            <Image
              width='105'
              height='36'
              src={`${Kernel.STATIC_KABUM}/conteudo/icons/logoPrime.svg`}
              alt='Logo Kabum prime'
            />
          ) : (
            <Image
              width='105'
              height='36'
              src={`${Kernel.STATIC_KABUM}/conteudo/icons/logo.svg`}
              alt='Logo Kabum'
            />
          )
        }
        searchVariant={String(searchVariant)}
      />
      <div id='main-content'>{children}</div>
      {renderNewsLetter}
      <Footer
        clean={isClean}
        isPrime={cookieIsPrime}
        kernel={Kernel}
        useDepartmentsMenu={useDepartmentsMenu}
        cookieIsMobile={cookieIsMobile}
        KabumLogo={
          cookieIsPrime ? (
            <Image
              width='158'
              height='54'
              src={`${Kernel.STATIC_KABUM}/conteudo/icons/logoPrime.svg`}
              alt='Logo Kabum prime'
            />
          ) : (
            <Image
              width='158'
              height='54'
              src={`${Kernel.STATIC_KABUM}/conteudo/icons/logo.svg`}
              alt='Logo Kabum'
            />
          )
        }
      />
    </>
  )
}
