import { useEffect, useState } from 'react'

import {
  IconArrowOrangeLeft,
  IconArrowOrangeRight,
  IconPopulars
} from '@k_frontend/icons'
import { Modal } from '@k_frontend/ui'

import { useHeaderContext } from '../../Header.context'
import * as S from './ModalSelectAddress.styles'
import { ModalAddressProps } from './ModalSelectAddress.types'

function ModalSelectAddress({ isOpen, handleClose }: ModalAddressProps) {
  const { addresses, currentAddress, setCurrentAddress } = useHeaderContext()

  function handleOnClickOption({ addressCode }) {
    setCurrentAddress(addressCode)
    handleClose()
  }

  const [addressPaginated, setAddressPaginated] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 3,
    totalPage: Math.ceil(addresses.length / 3)
  })

  const next = () => {
    if (pagination.page >= pagination.totalPage) {
      setPagination((prev) => ({
        ...prev,
        page: 1
      }))
    } else {
      setPagination((prev) => ({
        ...prev,
        page: prev.page + 1
      }))
    }
  }

  const prev = () => {
    if (pagination.page <= 1) {
      setPagination((prev) => ({
        ...prev,
        page: pagination.totalPage
      }))
    } else {
      setPagination((prev) => ({
        ...prev,
        page: prev.page - 1
      }))
    }
  }

  const itens = () => {
    const page = pagination.page - 1
    const start = page * pagination.perPage
    const end = start + pagination.perPage

    setAddressPaginated(addresses.slice(start, end))
  }

  useEffect(() => {
    itens()
  }, [pagination.page])

  return (
    <S.Container>
      <Modal.Modal
        isOpen={isOpen}
        handleClose={handleClose}
        width='360px'
        customPadding='16px'
        className='ModalSelectAddress'
      >
        <Modal.ModalHeader handleClose={handleClose} closeColor='#7F858D'>
          <Modal.ModalHeaderWithText
            icon={<IconPopulars className='icon' />}
            title='CEP'
          />
        </Modal.ModalHeader>
        <S.Container>
          <S.SubtTitle>Escolha um endereço cadastrado</S.SubtTitle>
          <S.ContainerItens>
            {addressPaginated?.map((address) => (
              <S.ContainerAddress
                selected={address.addressCode === currentAddress?.addressCode}
                key={address.addressCode}
                onClick={() =>
                  address.addressCode !== currentAddress.addressCode &&
                  handleOnClickOption(address)
                }
              >
                <S.identification>{address.identification}</S.identification>
                <S.ContainerStreet>
                  <S.Street>{`${address.street}, ${address.number}`}</S.Street>
                  <S.ContainerCode>
                    {`CEP ${address.zipCode} - ${address.city}, ${address.state} `}
                  </S.ContainerCode>
                </S.ContainerStreet>
              </S.ContainerAddress>
            ))}
          </S.ContainerItens>
          {addresses.length > pagination.perPage && (
            <S.ContainerButton>
              <S.Prev onClick={prev}>
                <IconArrowOrangeLeft />
              </S.Prev>
              <S.Next onClick={next}>
                <IconArrowOrangeRight />
              </S.Next>
            </S.ContainerButton>
          )}
        </S.Container>
      </Modal.Modal>
    </S.Container>
  )
}

export default ModalSelectAddress
