import { ResizeHOC } from '@k_frontend/core'

import { HeaderContainer } from 'containers/HeaderContainer'
import { HeaderContextProvider } from './Header.context'
import { HeaderControllerProps as Props } from './Header.types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export function Header(props: Props) {
  return (
    <div id='header-container'>
      <HeaderContextProvider {...props}>
        <ResizeHOC
          MobileComponent={HeaderMobile}
          DesktopComponent={HeaderDesktop}
          cookieIsMobile={props.cookieIsMobile}
        />
        <HeaderContainer />
      </HeaderContextProvider>
    </div>
  )
}
