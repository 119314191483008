import styled from 'styled-components'

import { TypeKabumInfoProps } from './Footer.types'

export const Container = styled.footer`
  max-width: 100vw;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white(500)};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    overflow: visible;
  }
`

export const LinksFooterCleaned = styled.div<TypeKabumInfoProps>`
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) => theme.spacing.sm};
  background: ${({ isPrime, theme }) =>
    isPrime ? theme.colors.black(900) : theme.colors.primary(500)};

  & > div {
    max-width: 500px;
    align-items: center;

    a {
      font-size: ${({ theme }) => theme.rem(10)};
      color: ${({ theme }) => theme.colors.white(500)};
      text-decoration: none;

      &:not(:last-child) {
        margin-right: ${({ theme }) => theme.spacing.md};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: baseline;

      a {
        gap: ${({ theme }) => theme.spacing.xxs};
      }
    }
  }
`
