import { BACKEND_LOGOUT } from 'settings/kernel'

export const redirectToLogout = (e) => {
  e.setRedirect(BACKEND_LOGOUT)
  return e
}

export const redirectToHome = (e) => {
  e.setRedirect('/')
  return e
}
