import { QueryClientProvider } from '@tanstack/react-query'
import LayoutController from 'components/Layout'
import { ToastContextProvider } from 'providers/Toast'
import { ThemeProvider } from 'styled-components'
import theme from 'theme'

import { useUser } from '@k_frontend/core'
import Notifications from 'react-notify-toast'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_CLIENT_ID } from 'settings/kernel'
import { queryClient } from 'utils/queryClient/queryClient'
import { AppProvidersProps } from './AppProviders.types'

export function AppProviders({ children, cookieIsMobile }: AppProvidersProps) {
  const { isPrime } = useUser()

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <LayoutController
            cookieIsMobile={cookieIsMobile}
            cookieIsPrime={isPrime}
          >
            <ToastContextProvider>{children}</ToastContextProvider>
            <Notifications options={{ zIndex: 9999999 }} />
          </LayoutController>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}
