import { useCallback, useContext } from 'react'
import { ToastContext } from './Toast'

export function useToast() {
  const { addToast, removeToast } = useContext(ToastContext)

  const showSuccess = useCallback(
    (message: string) => {
      addToast('success', message)
    },
    [addToast]
  )

  const showWarning = useCallback(
    (message: string) => {
      addToast('warning', message)
    },
    [addToast]
  )

  const showInfo = useCallback(
    (message: string) => {
      addToast('info', message)
    },
    [addToast]
  )

  const showError = useCallback(
    (message: string) => {
      addToast('error', message)
    },
    [addToast]
  )

  return {
    removeToast,
    showSuccess,
    showWarning,
    showInfo,
    showError
  }
}
