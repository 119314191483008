import { Fragment } from 'react'

import { Row, SocialMedia } from '@k_frontend/ui'

import { TypeFooterLinksProps } from '../FooterLinks.types'
import * as S from './FooterLinksDesktop.styles'

export function FooterLinksDesktop({ footerJson }: TypeFooterLinksProps) {
  return (
    <Row>
      <S.ColCustom lg={3} xl={3} className='departmentsColumn'>
        <S.ColumnTitle>Departamentos</S.ColumnTitle>
        <S.ColumnContent className='departmentLinks'>
          {footerJson?.departmentslinks?.map((department, index) => (
            <Fragment key={`${index}-departmentsLink`}>
              <S.ColumnLink href={department.link} target={department.target}>
                {department.title}
              </S.ColumnLink>
            </Fragment>
          ))}
        </S.ColumnContent>
      </S.ColCustom>
      <S.ColCustom lg={2} xl={2} className='newsAndPromotionsLinks'>
        <S.ColumnTitle>Novidades e Promoções</S.ColumnTitle>
        <S.ColumnContent>
          {footerJson?.newsAndPromotionsinks?.map((newsAndPromotion, index) => (
            <Fragment key={`${index}-newsAndPromotionLink`}>
              <S.ColumnLink
                href={newsAndPromotion.link}
                target={newsAndPromotion.target}
              >
                {newsAndPromotion.title}
              </S.ColumnLink>
            </Fragment>
          ))}
        </S.ColumnContent>
      </S.ColCustom>
      <S.ColCustom lg={2} xl={2} className='institutionalLinksColumn'>
        <S.ColumnTitle>Institucional</S.ColumnTitle>
        <S.ColumnContent className='institutionalLinks'>
          {footerJson?.institucionallinks?.map((item, index) => (
            <Fragment key={index}>
              <S.ColumnLink
                href={item.link}
                target={item.target}
                rel={item?.rel}
              >
                {item.title}
              </S.ColumnLink>
            </Fragment>
          ))}
        </S.ColumnContent>

        <br />
        <S.ColumnTitle>Compra Segura</S.ColumnTitle>
        <S.ColumnContent className='safeBuyLinks'>
          {footerJson?.warranty?.map((warranty, index) => (
            <Fragment key={`${index}-safeBuyLink`}>
              <S.ColumnLink href={warranty.link} target={warranty.target}>
                {warranty.title}
              </S.ColumnLink>
            </Fragment>
          ))}
        </S.ColumnContent>
      </S.ColCustom>
      <S.ColCustom lg={2} xl={2} className='myAccountColumn'>
        <S.ColumnTitle>Acessibilidade</S.ColumnTitle>
        <S.ColumnContent className='accessibilityLinks'>
          {footerJson?.accessibilityLinks?.map((item, index) => (
            <Fragment key={`${index}-accessibilitylink`}>
              <S.ColumnLink href={item.link} target={item.target}>
                {item.title}
              </S.ColumnLink>
            </Fragment>
          ))}
        </S.ColumnContent>

        <S.ColumnTitle>Minha Conta</S.ColumnTitle>
        <S.ColumnContent className='myAccountLinks'>
          {footerJson?.myAccountlinks?.map((item, index) => (
            <Fragment key={`${index}-myaccountlink`}>
              <S.ColumnLink href={item.link} target={item.target}>
                {item.title}
              </S.ColumnLink>
            </Fragment>
          ))}
        </S.ColumnContent>

        <S.ColumnSocialMediaList>
          {footerJson?.socialMedia && (
            <SocialMedia socialMediaLinks={footerJson.socialMedia} />
          )}
        </S.ColumnSocialMediaList>
        <S.ColumnTitle id='downloadAppsColumn'>
          Baixe os aplicativos
        </S.ColumnTitle>
        <S.ColumnContent>
          {footerJson?.applinks?.map((item, index) => (
            <S.ColumnAppLink
              key={index}
              dangerouslySetInnerHTML={{
                __html: item.component
              }}
              href={item.link}
              target={item.target}
              aria-label={item.label || ''}
            />
          ))}
        </S.ColumnContent>
      </S.ColCustom>
      <S.ColCustom lg={3} xl={3} className='attendanceColumn'>
        <S.ColumnTitle>Atendimento</S.ColumnTitle>
        <S.ColumnContent>
          <S.ColumnItem
            dangerouslySetInnerHTML={{
              __html: footerJson?.attendanceTime
            }}
          />
          <S.ColumnItem
            dangerouslySetInnerHTML={{
              __html: footerJson?.address
            }}
          />
        </S.ColumnContent>
        <S.ColumnTitle id='centralSacTitle'>Central SAC:</S.ColumnTitle>
        <a
          href='/faq'
          className='text-xs text-white-500 font-normal mt-8 hover:text-black-500'
        >
          Clique aqui
        </a>
      </S.ColCustom>
    </Row>
  )
}
